import React, { useState } from 'react'
import copy from 'copy-to-clipboard'
import PropTypes from 'prop-types'
import shareIt from '../../lib/sharing'
import { logSegEvent } from '../../lib/analytics'
import { BUTTON_CLICK } from '../../constants/analytics'
import FBConfirm from '../FBConfirm'
import logErr, { logMsg } from '../../lib/err'
import { CREW_TEXT } from '../../constants/messages'

const exp_invite = 'crew-invite'
const var_invite = '1'

const PendingIcon = '/static/landslide/icons/PendingIcon.png'

export default function Invite({ inviteCodeFn, showInviteForm, inviteCode }) {
  const [copyLinkModalOpen, _setCopyLinkModalOpen] = useState(false)
  const getCrewLink = (inviteCode) => {
    const prefix = process.env.I_DOMAIN ? process.env.I_DOMAIN + '.' : ''
    const inviteLink = inviteCode ? `https://${prefix}${process.env.DOMAIN}/c/` + inviteCode : ''
    return inviteLink
  }

  const setCopyLinkModalOpen = (open) => {
    _setCopyLinkModalOpen(open)
    if (open) logMsg('copy link modal')
  }

  const shareUniqueLink = async () => {
    // open up the form from here
    const inviteLink = getCrewLink(inviteCode)
    logSegEvent(BUTTON_CLICK, { experiment: exp_invite, variant: var_invite })
    try {
      if (inviteCodeFn) {
        const result = await shareIt({ text: CREW_TEXT, url: inviteLink })
        if (result === 'shared') {
          const invite = await inviteCodeFn({})
          inviteCode = invite.data.code
          showInviteForm(true, { code: inviteCode, name: '' })
        }
        if (result === 'error') {
          setCopyLinkModalOpen(true)
        }
      }
    } catch (e) {
      setCopyLinkModalOpen(true)
    }
  }

  const confirmAction = async (e) => {
    e.stopPropagation()
    const inviteLink = getCrewLink(inviteCode)
    copy(inviteLink)
    try {
      const invite = await inviteCodeFn({})
      inviteCode = invite.data.code
      setCopyLinkModalOpen(false)
      showInviteForm(true, { code: inviteCode, name: '' })
    } catch (e) {
      logErr(e, 'failed to show invite form')
    }
  }

  return (
    <div id="gtm-invite-member-btn" onClick={shareUniqueLink}>
      <div className="invite-friend-button invite-adjust">
        <div className="profile-pic-container ml-n1">
          <img src={PendingIcon} alt="Profile Picture" className="profile-pic" />
        </div>
        <div className="s-container">
          <div className="invite-title-container">
            <h3>Invite a friend to your Crew</h3>
            <div className="crew-status invite-subtitle">
              <p>Tap to send the invite</p>
            </div>
          </div>
        </div>
      </div>
      {copyLinkModalOpen && (
        <FBConfirm
          crew
          url={getCrewLink(inviteCode)}
          confirmAction={confirmAction}
          cancelAction={(e) => {
            e.stopPropagation()
            setCopyLinkModalOpen(false)
          }}
        />
      )}
    </div>
  )
}

Invite.propTypes = {
  inviteCodeFn: PropTypes.func.isRequired,
  showInviteForm: PropTypes.func.isRequired,
  inviteCode: PropTypes.string.isRequired,
}
