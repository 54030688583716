import Link from 'next/link'
import PropTypes from 'prop-types'

import UserForm from '../UserFormLg'

const ProfileIcon = '/static/landslide/icons/ProfileIconActive.png'
const Camera = '/static/landslide/icons/Camera.png'

function EditUserInfo({ user, setNavTab }) {
  return (
    <div id="user-form-reg">
      <div id="user-form-top">
        <Link href={'/user-photo'}>
          <a>
            <div className="logo-wrapper">
              {user.photoURL ? <img src={user.photoURL} alt="user photo" /> : <img src={ProfileIcon} alt="user photo" />}
              <img className="camera" src={Camera} alt="camera" />
            </div>
          </a>
        </Link>
      </div>
      <div id="form-container">
        <UserForm user={user} setNavTab={setNavTab} />
      </div>
    </div>
  )
}

EditUserInfo.propTypes = {
  setNavTab: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}

export default EditUserInfo
