import React from 'react'
import PropTypes from 'prop-types'

function CrewTabs({ crewTab, myCount, setCrewTab, upProfile }) {
  return (
    <div id="crew-tabs">
      <button className={crewTab === 'my-crew' ? 'active-btn' : ''} id="my-crew-tab" onClick={() => setCrewTab('my-crew')}>
        <p className={crewTab === 'my-crew' ? '' : 'muted'}>My Crew</p>
      </button>
      <button className={crewTab === 'up-crew' ? 'active-btn' : ''} id="up-crew-tab" onClick={() => setCrewTab('up-crew')}>
        <p className={crewTab === 'up-crew' ? '' : 'muted'}>{upProfile.fname}'s Crew</p>
      </button>
    </div>
  )
}

CrewTabs.propTypes = {
  crewTab: PropTypes.string.isRequired,
  myCount: PropTypes.number.isRequired,
  setCrewTab: PropTypes.func.isRequired,
  upProfile: PropTypes.object.isRequired,
}

export default CrewTabs
