export const IS_REGISTERED = 'isRegistered'
export const IS_NOT_REGISTERED = 'isNotRegistered'
export const TRIED_REGISTERING = 'triedRegistering'
export const IS_CHECKING = 'isChecking'
export const IS_REGISTERING = 'isRegistering'
export const IS_MONITORING_STATUS = 'isMonitoringStatus'
export const BY_MAIL_OR_ABSENTEE = 'byMailOrAbsentee'
export const IN_PERSON = 'inPerson'
export const NOT_SURE = 'notSure'
export const AUTO_MONITOR_STATUS = 'autoMonitorStatus'
export const VOTE_METHOD = 'voteMethod'
export const BALLOT_TRACKING = 'isTrackingBallot'
export const RESTRICTED_ELIGIBLE = 'isRestrictedEligible'
export const RECIEVED_BALLOT = 'receivedBallot'
export const IS_EARLY_VOTING = 'isEarlyVoting'
export const DID_VOTE_2020 = 'didVote2020'

export const YES = 'yes'
export const NO = 'no'
export const PENDING = 'pending'

export const CHECK_REG_RESULTS = 'checkRegResults'
