import React from 'react'
import PropTypes from 'prop-types'
import useBodyLockScroll from '../hooks/useBodyLockScroll'

function FBConfirm({ crew, url, confirmAction, cancelAction }) {
  useBodyLockScroll()
  return (
    <div id="fb-confirm">
      <div id="modal">
        <p>
          <b>Copy your {crew ? 'crew' : 'public'} link and share it</b>
        </p>
        <p>{url}</p>
        <div className="d-flex d-end">
          <button onClick={confirmAction}>copy link</button>
          <button onClick={cancelAction}>cancel</button>
        </div>
      </div>
    </div>
  )
}

FBConfirm.defaultProps = {
  crew: false,
}

FBConfirm.propTypes = {
  crew: PropTypes.bool,
  url: PropTypes.string.isRequired,
  confirmAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
}

export default FBConfirm
