import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import useBodyLockScroll from '../../../hooks/useBodyLockScroll'
import stateNames from '../../../constants/stateNames'
const CheckIcon = '/static/landslide/icons/RegCheckSuccess.png'

function OverlayRegStatusNotifyV2({ setNavTab, user }) {
  useBodyLockScroll()
  const isRegistered = user.voteInfo?.isRegistered === 'yes'
  return (
    <div id="overlay-reg-status-notify">
      <div className="content">
        {isRegistered ? (
          <div className="center-content">
            <img src={CheckIcon} alt="success" />
            <h3>Nice! You're registered to vote in {stateNames[user.state] || user.state}.</h3>
          </div>
        ) : (
          <div className="center-content">
            <h3>Hmm, looks like there might be an issue with your voter registration. We can help. </h3>
          </div>
        )}
        {isRegistered ? (
          <div className="center-content">
            <Button onClick={() => setNavTab('voter-info-early-voting')} primary className="bottom-aligned" block>
              Next
            </Button>
          </div>
        ) : (
          <div>
            <Button onClick={() => setNavTab('more-details')} primary block className="check-it-out">
              Check it out
            </Button>
            <Button onClick={() => setNavTab('voter-info-early-voting')} primary className="bottom-aligned" block>
              I'll look later
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

OverlayRegStatusNotifyV2.propTypes = {
  user: PropTypes.object.isRequired,
  setNavTab: PropTypes.func.isRequired,
}

export default OverlayRegStatusNotifyV2
