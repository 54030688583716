import React from "react";
import PropTypes from "prop-types";
import BottomDrawer from "./BottomDrawer";
const PowerIcon = "/static/landslide/icons/PowerIconBlack.png";

function PublicShareBottomDrawer({
  setDrawerIsOpen,
  shareUniqueLink,
  dismissShareMessage,
}) {
  return (
    <div id="power-message-container">
      <div id="power-message">
        <BottomDrawer
          isOpen
          handleClose={() => {
            setDrawerIsOpen(false);
          }}
        >
          <div className="content">
            <img src={PowerIcon} alt="crew icon" />
            <h3>Get ready to power up!</h3>
            <h4>
              Want to increase your power score without adding people to your
              crew?
            </h4>
            <p>
              Share Landslide with this link and you'll get credit for every
              person who joins because of you.
            </p>
          </div>
          <button
            id="gtm-close-welcome-message"
            onClick={shareUniqueLink}
            className="btn btn-primary btn-block"
          >
            Start Sharing
          </button>
          <button
            id="gtm-dismiss-welcome-message"
            onClick={dismissShareMessage}
            className="btn btn-primary btn-block transparent"
          >
            Don't show this again
          </button>
        </BottomDrawer>
      </div>
    </div>
  );
}

PublicShareBottomDrawer.propTypes = {
  setDrawerIsOpen: PropTypes.func.isRequired,
  shareUniqueLink: PropTypes.func.isRequired,
  dismissShareMessage: PropTypes.func.isRequired,
};

export default PublicShareBottomDrawer;
