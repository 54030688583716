import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import firebase from 'firebase/app'
import { Button } from 'react-bootstrap'
import { setItem } from '../../lib/asyncStorage'
import { logPageView, logSegEvent } from '../../lib/analytics'
import { BUTTON_CLICK } from '../../constants/analytics'
import { useStore } from '../../hooks'
import { DokaModal } from '../../react-doka'
const cameraIcon = '/static/landslide/icons/Photo.png'
import { VOTE_2020_CAMPAIGN } from '../../constants/posse'

const INITIAL_PHOTO_STATE = {
  enabled: false,
  image: '',
  src: '',
  srcPrev: null,
  crop: {
    aspectRatio: 1,
  },
}

const experiment = 'crew-photo-prompt'
const variant = 1

const mask = (root, setInnerHTML) => {
  // https://pqina.nl/doka/docs/patterns/api/doka-instance/#setting-the-crop-mask
  setInnerHTML(
    root,
    `
                    <mask id="my-mask">
                        <rect x="0" y="0" width="100%" height="100%" fill="white"/>
                        <circle cx="50%" cy="50%" r="50%" fill="black"/>
                    </mask>
                    <rect fill="rgba(255,255,255,.3125)" x="0" y="0" width="100%" height="100%" mask="url(#my-mask)"/>
                    <circle cx="50%" cy="50%" r="50%" fill="transparent" stroke-width="1" stroke="#fff"/>
                `
  )
}

function CrewPhotoPrompt({ setShowPhotoPrompt }) {
  const [selectedPhoto, setSelectedPhoto] = useState(null)
  const [uploadedPhotoURL, setUploadedPhotoURL] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadDone, setUploadDone] = useState(false)
  const [prevPhoto, setPrevPhoto] = useState(null)
  const [photoState, setPhotoState] = useState(INITIAL_PHOTO_STATE)
  const [{ auth, me }, dispatch] = useStore()

  const updateProfile = firebase.functions().httpsCallable('updateProfile')

  useEffect(() => {
    logPageView('user-photo-crew')
  }, [])

  const handleFileChangePhoto = (e) => {
    if (!e.target.files.length) return
    setPrevPhoto(null)
    setPhotoState({
      ...photoState,
      srcPrev: photoState.src,
      src: e.target.files[0],
      enabled: true,
    })
  }

  const handleDokaConfirmPhoto = async (output) => {
    setPhotoState({
      ...photoState,
      srcPrev: null,
      enabled: false,
      image: output.file,
      crop: output.data.crop,
    })
    setSelectedPhoto(output.file)
    try {
      const { firebase, user } = auth
      const uid = user?.uid
      if (!firebase || !uid) return
      setIsUploading(true)
      const storageRef = firebase.storage().ref()
      const fileRef = storageRef.child(`images/profile/${uid}/avatar.jpg`)
      const snap = await fileRef.put(output.file)
      const url = await snap.ref.getDownloadURL()
      setUploadedPhotoURL(url)
      await updateProfile({
        campaign: VOTE_2020_CAMPAIGN,
        profile: { photoURL: url },
      })
      logSegEvent(BUTTON_CLICK, {
        experiment,
        variant,
        tag: 'photo-upload-complete',
      })
      setUploadDone(true)
    } catch (e) {
      console.log('ERROR', e)
    } finally {
      setIsUploading(false)
    }
  }

  const handleDokaCancelPhoto = () => {
    setPhotoState({
      ...photoState,
      src: photoState.srcPrev || photoState.src,
      srcPrev: null,
      enabled: false,
    })
  }

  const skipUpload = async () => {
    await setItem('declinedPhotoPrompt', 'yes')
    logSegEvent(BUTTON_CLICK, {
      experiment,
      variant,
      tag: 'photo-upload-skip',
    })
    setShowPhotoPrompt(false)
  }

  const url = photoState?.image ? URL.createObjectURL(photoState.image) : null

  return (
    <div id="crew-photo-prompt">
      <div id="photo-top-section">
        <h2>You can invite friends and family to join your crew and vote early with you. They love to see your face 😀</h2>
        <p className="description">Select your photo:</p>
        {!selectedPhoto && !prevPhoto ? (
          <div className="avatar-file-pick">
            <>
              <input type="file" accept="image/*" name="userPhoto" className="file-picker" onChange={handleFileChangePhoto} />
              <img className="camera-icon" src={cameraIcon} alt="profile-img" />
            </>
          </div>
        ) : (
          <img className="profile" src={prevPhoto || url} />
        )}
        {photoState.enabled && (
          <DokaModal
            utils={['crop', 'filter', 'color']}
            src={photoState.src}
            cropAspectRatio={1}
            crop={photoState.crop}
            cropMask={mask}
            outputData={true}
            onConfirm={handleDokaConfirmPhoto}
            onCancel={handleDokaCancelPhoto}
            outputWidth={250}
            outputType="image/jpeg"
          />
        )}
      </div>
      <div id="photo-bottom-section">
        {!selectedPhoto && (
          <button onClick={skipUpload} id="skip">
            Skip this step
          </button>
        )}
        <Button
          className="mt-4"
          disabled={!selectedPhoto}
          primary="true"
          block
          onClick={() => {
            logSegEvent(BUTTON_CLICK, {
              experiment,
              variant,
              tag: 'photo-upload-advance',
            })
            setShowPhotoPrompt(false)
          }}
        >
          {isUploading ? 'Uploading' : 'View My Crew'}
        </Button>
      </div>
    </div>
  )
}

CrewPhotoPrompt.propTypes = {
  setShowPhotoPrompt: PropTypes.func.isRequired,
}

export default CrewPhotoPrompt
