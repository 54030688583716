import React from 'react'
import PropTypes from 'prop-types'
import BottomDrawer from '../BottomDrawer'
import useCheckReg from '../../hooks/useCheckReg'
import firebase from 'firebase/app'
import logErr from '../../lib/err'

function NeedsToRecheckDrawer({ setNavTab, setNeedsToRecheck, user, info }) {
  const [{ RegSuccessOverlay, pending, error }, checkRegistration] = useCheckReg({
    setNavTab,
    user,
    info,
    onClose: () => dismissDrawer(),
  })

  const updateProfile = firebase.functions().httpsCallable('updateProfile')

  const dismissDrawer = async (clearFlag = false) => {
    try {
      if (clearFlag) {
        updateProfile({
          profile: {
            voteInfo: {
              ...user.voteInfo,
              needsToRecheck: false,
            },
          },
        }).catch((err) => {
          logErr(err, 'failed to updateProfile')
        })
      }
    } catch (e) {
      console.log('e', e)
    } finally {
      setNeedsToRecheck(false)
    }
  }

  const goRecheck = async () => {
    try {
      await checkRegistration()
    } catch (e) {
      console.log('e', e)
    }
  }

  return (
    <div id="needs-to-recheck-modal">
      <BottomDrawer isOpen noCloseIcon>
        <div className="content">
          <h3>Since you updated your address, you should check your voter registration again.</h3>
          {error && <div className="error">{error}</div>}
          <button onClick={goRecheck} className="btn btn-primary btn-block">
            {pending ? 'Checking ...' : 'Check Registration Status'}
          </button>
          <button onClick={() => dismissDrawer(true)} className="btn btn-primary btn-block transparent">
            Dismiss
          </button>
        </div>
        <RegSuccessOverlay />
      </BottomDrawer>
    </div>
  )
}

NeedsToRecheckDrawer.propTypes = {
  setNavTab: PropTypes.func.isRequired,
  setNeedsToRecheck: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
}

export default NeedsToRecheckDrawer
