import React from 'react'
import PropTypes from 'prop-types'

const closeIcon = '/static/landslide/icons/Close.png'
const arrowIcon = '/static/landslide/icons/arrow.png'

function BottomDrawer({ children, fullScreen, noCloseIcon, isOpen, handleClose, goBack }) {
  if (!isOpen) {
    return null
  }

  return (
    <div id="bottom-drawer">
      <div id="drawer-background" onClick={handleClose} />
      <div id="drawer-content-wrapper" className={`${fullScreen && 'full-screen'}`}>
        <div className="action-icon-row">
          {/* TODO: implement post MVP */}
          {goBack ? <img src={arrowIcon} onClick={goBack} id="gtm-go-back-bottom-drawer" alt="go back one step" /> : <p> </p>}
          <p> </p>
          {!noCloseIcon && <img src={closeIcon} onClick={handleClose} id="gtm-close-drawer" alt="close drawer" />}
        </div>
        <div id="drawer-content">{children}</div>
        <div />
      </div>
    </div>
  )
}

BottomDrawer.defaultProps = {
  fullScreen: false,
  goBack: null,
  isOpen: false,
  noCloseIcon: false,
}

BottomDrawer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  fullScreen: PropTypes.bool,
  goBack: PropTypes.func,
  noCloseIcon: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
}

export default BottomDrawer
