import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { logSegIdentity, logSegEvent } from '../../lib/analytics'
import { BUTTON_CLICK } from '../../constants/analytics'
import recommendations from '../../constants/recommendationsInfo'
import ExtendedVotingInfo from './ExtendedVotingInfo'
import { getShortDate, shortedDateSpan } from '../../lib/dates'
import { isMobileDevice } from '../../lib/sharing'
import Recommendation from './Recommendation'

const ArrowDown = '/static/landslide/icons/arrow-down.png'
const experiment = 'set-vote-preference'
const VoteAmerica = '/static/landslide/images/VoteAmerica.png'
const GoogleApi = '/static/landslide/images/GoogleCivicApi.png'
const variant = 1

function SetPreference({ state, info, setPreference, setView }) {
  const [showMoreInfo, setShowMoreInfo] = useState(false)
  const [openOption, setOpenOption] = useState(0)
  const earlyVoteStartDate = getShortDate(info.l2020_early_voting_starts_date.value)
  const earlyVoteEndDate = getShortDate(info.l2020_early_voting_ends_date.value)
  const options = info.l2020_voting_options
  const list = options.map((o, i) => {
    return { ...recommendations[o], key: o, rank: i + 1 }
  })
  const selectPref = (key) => {
    logSegIdentity(null, { voterPref: key })
    logSegEvent(BUTTON_CLICK, {
      experiment,
      variant,
      voterPref: key,
    })
    setPreference(key)
    setView('form')
  }

  return (
    <div id="set-preference">
      <header>
        <div>
          <div className="top-bar">
            <button className="back-button" onClick={() => setView('enter-zip')}>
              <img id="gtm-page-header-go-back" src="/static/landslide/icons/arrow.png" alt="go back" />
            </button>
            <h1>Landslide</h1>

            <h3></h3>
          </div>
          <h3>Recommendations for {state.state}</h3>
          {earlyVoteStartDate && earlyVoteEndDate && <h4>Your Early Voting Days are {shortedDateSpan(earlyVoteStartDate, earlyVoteEndDate)}</h4>}
          {!showMoreInfo ? (
            <button onClick={() => setShowMoreInfo(true)}>
              See more voting info <img src={ArrowDown} alt="more information" />{' '}
            </button>
          ) : (
            <ExtendedVotingInfo info={info} setShowMoreInfo={setShowMoreInfo} setView={setView} state={state.state} />
          )}
        </div>
      </header>
      <main>
        <p id="options-intro">
          Landslide's rankings are based on your state’s unique voting rules. Our goal is to help get your vote counted on or before Election Night.
        </p>
        {list.map((l, i) => {
          return (
            <Recommendation
              key={l.key}
              index={i}
              isOpen={i === openOption}
              setOpen={setOpenOption}
              stateInfo={info}
              recommendation={l}
              setNavTab={setView}
              isExternal={true}
              isMobileDevice={isMobileDevice}
              onSelect={selectPref}
            />
          )
        })}
        <div className="donation-card">
          <h2>Landslide is a small non-profit. Can you pitch in $3 to help us fight the good fight?</h2>
          <a href="https://secure.actblue.com/donate/jointhelandslide" target="_blank" rel="noreferrer">
            <button className="btn btn-primary btn-block">
              Donate
            </button>
          </a>
        </div>
        <div className="powered-by">
          <b>Powered by</b>
          <img src={VoteAmerica} alt="Vote America Logo" />
          <img src={GoogleApi} alt="Google Civic API Logo" />
        </div>
      </main>
    </div>
  )
}

SetPreference.propTypes = {
  state: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  setPreference: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
}

export default SetPreference
