import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import firebase from 'firebase/app'
import scrollToTop from '../../lib/scrollToTop'
import Link from 'next/link'
import { getItem, setItem } from '../../lib/asyncStorage'
import { Form } from 'react-bootstrap'
const Arrow = '/static/landslide/icons/path-4.png'
const WhiteArrow = '/static/landslide/icons/WhiteArrow.png'

import PrivacyPolicy from '../PrivacyPolicy'
import TouContent from '../TouContent'
import FAQ from './FAQ'

function MoreSection({ user }) {
  const [subSection, setSubSection] = useState('')
  const [showNudgeWarningModal, setShowNudgeWarningModal] = useState(false)
  const [acceptedNudgeWarning, setAcceptedNudgeWarning] = useState(false)
  const [allowNudgingInternal, setAllowNudgingInternal] = useState(user.allowNudging)
  const updateProfile = firebase.functions().httpsCallable('updateProfile')

  useEffect(() => {
    const asyncHelper = async () => {
      const accepted = await getItem('acceptedNudgeWarning')
      setAcceptedNudgeWarning(accepted)
    }
    asyncHelper()
  }, [])

  useEffect(() => {
    scrollToTop()
  }, [subSection])

  const allowNudging = async (value) => {
    setAllowNudgingInternal(value)
    try {
      await updateProfile({
        campaign: 'vote2020',
        profile: { allowNudging: value },
      })
    } catch (e) {
      setAllowNudgingInternal(!value) // request failed, showing previous value
    }
  }

  const nudgeButtonClick = (value) => {
    if (value && acceptedNudgeWarning) {
      allowNudging(value)
    }
    if (!value) {
      allowNudging(value)
    }
    if (value && !acceptedNudgeWarning) {
      setShowNudgeWarningModal(true)
    }
  }

  const toggleCheck = async (e) => {
    const { checked } = e.currentTarget || false
    setAcceptedNudgeWarning(checked)
    await setItem('acceptedNudgeWarning', checked)
  }

  const handleCancel = async () => {
    setAcceptedNudgeWarning(false)
    setShowNudgeWarningModal(false)
    await setItem('acceptedNudgeWarning', false)
  }

  const handleConfirm = () => {
    setShowNudgeWarningModal(false)
    allowNudging(true)
  }

  const currentVersion = process.env.CURRENT_VERSION.split('-')[0]

  return (
    <div id="more-section">
      <div id="top-section">
        {subSection && (
          <button onClick={() => setSubSection('')}>
            <img src={WhiteArrow} />
          </button>
        )}
        {!subSection ? <h1>Landslide</h1> : <h1 className="smaller">{subSection}</h1>}
      </div>
      <main>
        {!subSection && (
          <ul className="options">
            <li className="allow-nudge">
              <p>Allow Nudging</p>
              <div>
                <button className={allowNudgingInternal ? 'selected yes' : 'yes'} onClick={() => nudgeButtonClick(true)}>
                  Yes
                </button>
                <button className={!allowNudgingInternal ? 'selected no' : 'no'} onClick={() => nudgeButtonClick(false)}>
                  No
                </button>
              </div>
            </li>    
            <li onClick={() => setSubSection('FAQs')}>
              <p>Frequently Asked Questions</p>
              <img src={Arrow} />
            </li>
            <li onClick={() => setSubSection('Terms of Use')}>
              <p>Terms Of Use</p>
              <img src={Arrow} />
            </li>
            <li onClick={() => setSubSection('Privacy Policy')}>
              <p>Privacy Policy</p>
              <img src={Arrow} />
            </li>
            <a href="https://secure.actblue.com/donate/jointhelandslide" target="_blank" rel="noreferrer">
              <p>Donate</p>
              <img src={Arrow} />
            </a>
            <a href="mailto:support@landslide2020.org" target="_blank" rel="noreferrer">
              <p>Contact Us</p>
              <img src={Arrow} />
            </a>

            <a href="" className="logout">
              <Link href="/logout">
                <div>
                  <p>Log Out</p>
                  <img src={Arrow} />
                </div>
              </Link>
            </a>
            <li>
              <p>App Version</p>
              <p>{currentVersion}</p>
            </li>
          </ul>
        )}
        {subSection === 'Privacy Policy' && <PrivacyPolicy />}
        {subSection === 'Terms of Use' && <TouContent />}
        {subSection === 'FAQs' && <FAQ />}
      </main>
      {showNudgeWarningModal && (
        <div id="nudge-warning-wrapper">
          <div id="nudge-warning">
            <h3>Are you sure?</h3>
            <p>Nudging allows people in your crew to text you, and you can text them back. Your phone number will be visible to them.</p>
            <form>
              <Form.Check
                custom
                type="checkbox"
                id="is-tracking"
                label="Don't show this again"
                checked={acceptedNudgeWarning}
                onChange={toggleCheck}
              />
            </form>
            <div id="button-row">
              <button onClick={handleCancel} className="btn transparent">
                Cancel
              </button>
              <button onClick={handleConfirm} className="btn btn-primary">
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

MoreSection.propTypes = {
  user: PropTypes.object.isRequired,
}

export default MoreSection
