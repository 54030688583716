import React, { useEffect, useState } from 'react'
import { getItem, setItem } from '../../lib/asyncStorage'

import differenceInHours from 'date-fns/differenceInHours'
import BottomDrawer from '../BottomDrawer'
import { logSegEvent } from '../../lib/analytics'
import { BUTTON_CLICK } from '../../constants/analytics'
const PosseIcon = '/static/landslide/icons/Posses.png'

const SEG_DISMISSED = 'welcome-dismissed'
const SEG_CLOSED = 'welcome-closed'
const experiment = 'welcome-message'
const variant = '1'

function WelcomeMessage() {
  const [welcomeMessageOpen, setWelcomeMessageOpen] = useState(true)
  const [neverShow, setNeverShow] = useState(false)
  const [lastShown, setLastShown] = useState()
  const [loading, setLoading] = useState(true)
  const [timeStamp, setTimeStamp] = useState(new Date().toISOString())

  useEffect(() => {
    const asyncHelper = async () => {
      const show = await getItem('neverShowWelcomeMessage')
      setNeverShow(show)
      const last = (await getItem('welcomeMessageLastShown')) || null
      setLastShown(last)
      setLoading(false)
    }
    asyncHelper()
    const int = setInterval(() => {
      setTimeStamp(new Date().toISOString())
      asyncHelper()
      setTimeout(() => {
        setWelcomeMessageOpen(true)
      }, 100) //delayed to deal with async storage
    }, 60 * 1000) // check every minture
    return () => clearInterval(int)
  }, [])

  const dismissWelcomeMessage = async () => {
    setWelcomeMessageOpen(false)
    logSegEvent(BUTTON_CLICK, { experiment, variant, tag: SEG_DISMISSED })
    await setItem('neverShowWelcomeMessage', true)
  }

  const handleWelcomeMessageHide = async () => {
    setWelcomeMessageOpen(false)
    logSegEvent(BUTTON_CLICK, { experiment, variant, tag: SEG_CLOSED })
    await setItem('welcomeMessageLastShown', new Date())
  }

  if (neverShow || loading || !welcomeMessageOpen) {
    return null
  }

  // only show after 24 hours again
  if (lastShown && differenceInHours(new Date(), new Date(lastShown)) < 24) {
    return null
  }

  return (
    <div id="welcome-message-container" key={timeStamp}>
      <div id="welcome-message">
        <BottomDrawer isOpen={welcomeMessageOpen} handleClose={handleWelcomeMessageHide} noCloseIcon>
          <div className="content">
            <img src={PosseIcon} alt="crew icon" />
            <h3>Welcome to your crew!</h3>
            <h4>Your crew is the group of folks you want to help vote early and win the election with you.</h4>
            <p>Click "Invite a friend" to share your unique crew invitation and start your Landslide.</p>
          </div>
          <button id="gtm-close-welcome-message" onClick={handleWelcomeMessageHide} className="btn btn-primary btn-block">
            Got it!
          </button>
          <button id="gtm-dismiss-welcome-message" onClick={dismissWelcomeMessage} className="btn btn-primary btn-block transparent">
            Don't show this again
          </button>
        </BottomDrawer>
      </div>
    </div>
  )
}

export default WelcomeMessage
