import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import smsLink from 'sms-link'
import { Button, Form } from 'react-bootstrap'
import { setItem, getItem, removeItem } from '../../lib/asyncStorage'

function NudgeWarning({ acceptedNudgeWarning, cancelAction, confirmAction, member, setAcceptedNudgeWarning }) {
  useEffect(() => {
    const asyncHelper = async () => {
      const accepted = await getItem('acceptedNudgeWarning')
      if (!accepted) {
        // Has not been set, defaults to checkbox st
        setAcceptedNudgeWarning(true)
        await setItem('acceptedNudgeWarning', 'yes')
        return
      }
    }
    asyncHelper()
  }, [])

  const toggleCheck = async (e) => {
    const { checked } = e.currentTarget
    setAcceptedNudgeWarning(checked)
  }

  const handleCancel = async () => {
    await removeItem('acceptedNudgeWarning')
    setAcceptedNudgeWarning(false)
    cancelAction()
  }

  return (
    <div id="nudge-warning-wrapper">
      <div id="nudge-warning">
        <div className="d-flex jcc">
          <h3> Give'em a nudge!</h3>
        </div>
        <p>
          When you nudge someone you send them a text message from your phone number, and your crew member can text you back. This will share your
          number with them.
        </p>
        <form>
          <Form.Check custom type="checkbox" id="is-tracking" label="Don't show this again" checked={acceptedNudgeWarning} onChange={toggleCheck} />
        </form>
        <div id="button-row">
          <button onClick={handleCancel} className="btn transparent">
            Cancel
          </button>
          <Button
            href={smsLink({
              phone: member.phone,
              body: `Hey ${member.fname}! Just giving you a little nudge to come back and get your Landslide going. We all know how high the stakes are for this election. \nhttps://landslide2020.org`,
            })}
            onClick={confirmAction}
            className="btn btn-primary"
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  )
}

NudgeWarning.defaultProps = {
  acceptedNudgeWarning: false,
}

NudgeWarning.propTypes = {
  cancelAction: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
  member: PropTypes.object.isRequired,
  setAcceptedNudgeWarning: PropTypes.func.isRequired,
  acceptedNudgeWarning: PropTypes.bool,
}

export default NudgeWarning
