import PropTypes from 'prop-types'
import { IS_MONITORING_STATUS, AUTO_MONITOR_STATUS } from '../../../constants/voterInfo'

import ExternalLink from '../../ExternalLink'

function PendingRegistrationForm({ isMonitoringStatus, usesInternalRegCheck, handleButtonClick, info, setNavTab, externalLinkButtonRender }) {
  const currentDate = () => {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    const d = new Date()
    return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`
  }

  return (
    <>
      {!isMonitoringStatus && usesInternalRegCheck && (
        <>
          <button onClick={() => handleButtonClick(IS_MONITORING_STATUS, AUTO_MONITOR_STATUS, true)} className="btn btn-primary btn-block">
            Monitor Registration Status
          </button>

          <p>We can check your voter registration status for you, and let you know when your application goes through.</p>
        </>
      )}

      {!isMonitoringStatus && !usesInternalRegCheck && (
        <ExternalLink
          onClick={() => handleButtonClick(IS_MONITORING_STATUS, AUTO_MONITOR_STATUS, false)}
          link={externalLinkButtonRender('Check Registration Status')}
          url={info.external_tool_verify_status.value}
          button={true}
        />
      )}

      {isMonitoringStatus && (
        <>
          {usesInternalRegCheck && <p id="waiting-subtitle">We will keep checking and let you know when your application goes through.</p>}

          <p>
            We last checked your registration status on {currentDate()}. <a onClick={() => setNavTab('more-details')}>View your voter status</a>
          </p>
        </>
      )}
    </>
  )
}

PendingRegistrationForm.propTypes = {
  setNavTab: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  externalLinkButtonRender: PropTypes.func,
  info: PropTypes.object,
  isMonitoringStatus: PropTypes.bool,
  usesInternalRegCheck: PropTypes.bool,
  pending: PropTypes.bool,
}

export default PendingRegistrationForm
