import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import firebase from 'firebase/app'
import { logPageView, logSegIdentity } from '../../lib/analytics'
import LoadingSpinner from '../LoadingSpinner'
import stateNames from '../../constants/stateNames'

function LoadStateInfo({ stateAbbr, setInfo, setView }) {
  const getStateInformation = firebase.functions().httpsCallable('getStateInformation')

  const getStateInfo = async (state) => {
    try {
      const { data } = await getStateInformation({ state })
      logSegIdentity(null, { state })
      setInfo(data)
      setView('preference')
    } catch (e) {
      console.log('e', e)
    }
  }

  useEffect(() => {
    logPageView(`landing-known-state`)
  }, [])

  useEffect(() => {
    console.log('LoadingStateInfo state', stateAbbr)
    if (stateAbbr) getStateInfo(stateAbbr)
  }, [stateAbbr])

  return (
    <div id="set-preference">
      <header>
        <div>
          <div className="top-bar">
            <button className="back-button" onClick={() => setView('enter-zip')}>
              <img id="gtm-page-header-go-back" src="/static/landslide/icons/arrow.png" alt="go back" />
            </button>
            <h1>Landslide</h1>
            <h3></h3>
          </div>
          <h3>Recommendations for {stateNames[stateAbbr]}</h3>
          <h4></h4>
        </div>
        <LoadingSpinner />
      </header>
    </div>
  )
}

LoadStateInfo.defaultProps = {}

LoadStateInfo.propTypes = {
  stateAbbr: PropTypes.string.isRequired,
  setInfo: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
}

export default LoadStateInfo
