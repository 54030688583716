import { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { getItem, setItem } from '../../lib/asyncStorage'
import copy from 'copy-to-clipboard'
import { useStore, useFunctions, useInviteForm } from '../../hooks'
import { VOTE_2020_CAMPAIGN } from '../../constants/posse'
import shareIt from '../../lib/sharing'
import { logSegEvent } from '../../lib/analytics'
import { BUTTON_CLICK } from '../../constants/analytics'
import { POWER_TEXT } from '../../constants/messages'

// components
import CrewDetails from './CrewDetails'
import CrewTabs from './CrewTabs'
import LoadingSpinner from '../LoadingSpinner'
import ActionCardsBar from './ActionCardsBar'
import WelcomeMessage from './WelcomeMessage'
import InvitationTrackForm from './InvitationTrackForm'
import PublicShareBottomDrawer from '../../components/PublicShareBottomDrawer'
import CrewPhotoPrompt from './CrewPhotoPrompt'
import FBConfirm from '../../components/FBConfirm'

const experiment = 'action-card-share'
const variant = '1'
const tag_dismiss = 'action-card-share-dismiss'
const tag_start = 'action-card-share-start'

export default function CrewPage({ toggleModal, user, setNavTab, votingInfo, votingInfoByState }) {
  const [{ posses, profiles }] = useStore()
  const [isOpenInviteForm, showInviteForm, inviteData] = useInviteForm()
  const fns = useFunctions()
  const inviteCodeFn = fns && fns.httpsCallable('inviteCode')
  const [crewTab, setCrewTab] = useState('my-crew')
  // power share message states
  const [publicShareDrawerIsOpen, setPublicShareDrawerIsOpen] = useState(false)
  const [copyLinkModalOpen, setCopyLinkModalOpen] = useState(false)
  const [showPowerMessage, setShowPowerMessage] = useState(true)
  const [referLink, setReferLink] = useState('')
  const [initialized, setInitialized] = useState(false)
  const [showPhotoPrompt, setShowPhotoPrompt] = useState(!user.photoURL)

  useEffect(() => {
    const asyncHelper = async () => {
      const dontShow = await getItem('neverShowPowerMessage')
      const declined = await getItem('declinedPhotoPrompt')
      setShowPhotoPrompt(!user.photoURL && declined !== 'yes')
      setShowPowerMessage(!dontShow)
      setInitialized(true)
    }
    const referCode = user?.refer || ''
    const prefix = process.env.I_DOMAIN ? process.env.I_DOMAIN + '.' : ''
    const link = referCode ? `https://${prefix}${process.env.DOMAIN}/r/${referCode}` : ''
    setReferLink(link)
    asyncHelper()
  }, [])

  const inviteCode = user.inviteCode || user.refer

  const myPIDs = user?.posses?.vote2020
  const myPID = myPIDs && myPIDs.my
  const myPosse = myPID && posses && posses[myPID]
  const myCount = myPosse && Object.keys(myPosse.members).length
  const upPosse = posses && posses[myPIDs.up]
  const upProfile = upPosse && profiles && profiles[upPosse.captain]
  const hasMembers = (myPosse?.members && Object.keys(myPosse.members).length > 1) || upPosse
  const hasInvitees = myPosse?.invitees && Object.keys(myPosse.invitees).length > 0

  const isLoading = !user || !posses || ((hasMembers || hasInvitees) && !profiles)

  const confirmAction = (e) => {
    e.stopPropagation()
    copy(referLink)
    setCopyLinkModalOpen(false)
  }

  const dismissShareMessage = async () => {
    await setItem('neverShowPowerMessage', true)
    setShowPowerMessage(false)
    logSegEvent(BUTTON_CLICK, { experiment, variant, tag: tag_dismiss })
    shareUniqueLink()
  }

  const shareUniqueLink = async () => {
    logSegEvent(BUTTON_CLICK, { experiment, variant, tag: tag_start })
    try {
      const result = await shareIt({ text: POWER_TEXT, url: referLink })
      if (result === 'error') {
        setCopyLinkModalOpen(true)
      }
    } catch (e) {
      setCopyLinkModalOpen(true)
    } finally {
      setPublicShareDrawerIsOpen(false)
    }
  }

  if (!initialized) {
    return null
  }

  if (showPhotoPrompt) {
    return <CrewPhotoPrompt user={user} setShowPhotoPrompt={setShowPhotoPrompt} />
  }

  return (
    <div id="crew">
      <ActionCardsBar
        inviteCodeFn={inviteCodeFn}
        showInviteForm={showInviteForm}
        inviteCode={inviteCode}
        user={user}
        toggleModal={toggleModal}
        setNavTab={setNavTab}
        setPublicShareDrawerIsOpen={setPublicShareDrawerIsOpen}
        showPowerMessage={showPowerMessage}
        shareUniqueLink={shareUniqueLink}
      />
      {isLoading && (
        <div className="crewContainer ">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && user && myPosse && !upPosse && (
        <div className="crew-container">
          <CrewDetails
            crew={myPosse}
            user={user}
            myPID={myPID}
            inviteCodeFn={inviteCodeFn}
            showInviteForm={showInviteForm}
            inviteCode={inviteCode}
            setNavTab={setNavTab}
            votingInfo={votingInfo}
            votingInfoByState={votingInfoByState}
          />
        </div>
      )}

      {!isLoading && user && upProfile && myPosse && upPosse && (
        <Fragment>
          <CrewTabs crewTab={crewTab} setCrewTab={setCrewTab} myCount={myCount} upProfile={upProfile} />
          {crewTab === 'my-crew' && user && myPosse && (
            <div className="crew-container">
              <CrewDetails
                crew={myPosse}
                user={user}
                myPID={myPID}
                inviteCodeFn={inviteCodeFn}
                showInviteForm={showInviteForm}
                inviteCode={inviteCode}
                setNavTab={setNavTab}
                votingInfo={votingInfo}
                votingInfoByState={votingInfoByState}
              />
            </div>
          )}
          {crewTab === 'up-crew' && user && upPosse && (
            <div className="crew-container">
              <CrewDetails
                crew={upPosse}
                user={user}
                myPID={myPID}
                upCrew
                inviteCodeFn={inviteCodeFn}
                showInviteForm={showInviteForm}
                inviteCode={inviteCode}
                setNavTab={setNavTab}
                votingInfo={votingInfo}
                votingInfoByState={votingInfoByState}
              />
            </div>
          )}
        </Fragment>
      )}
      {isOpenInviteForm && <InvitationTrackForm showInviteForm={showInviteForm} inviteData={inviteData} inviteCodeFn={inviteCodeFn} />}
      <WelcomeMessage />
      {publicShareDrawerIsOpen && (
        <PublicShareBottomDrawer
          setDrawerIsOpen={setPublicShareDrawerIsOpen}
          shareUniqueLink={shareUniqueLink}
          dismissShareMessage={dismissShareMessage}
        />
      )}
      {copyLinkModalOpen && (
        <FBConfirm
          url={referLink}
          confirmAction={confirmAction}
          cancelAction={(e) => {
            e.stopPropagation()
            setCopyLinkModalOpen(false)
          }}
        />
      )}
      {/* {earlyVotingRoute && (
        <EarlyVotingPhotoUpload />
      )} */}
    </div>
  )
}

CrewPage.defaultProps = {
  votingInfo: null,
  votingInfoByState: null,
}

CrewPage.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  setNavTab: PropTypes.func.isRequired,
  votingInfo: PropTypes.object,
  votingInfoByState: PropTypes.object,
}
