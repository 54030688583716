import { useEffect } from 'react'
import PropTypes from 'prop-types'
import distance from '@turf/distance'
import sort from 'lodash/sortBy'
import scrollToTop from '../../lib/scrollToTop'
import { round } from '../../lib/util'

import VotingLocation from './VotingLocation'

export default function Locations({ locations, myLocation }) {
  useEffect(() => {
    scrollToTop()
  }, [])

  let sorted = []
  locations.forEach((l) => {
    const { latitude, longitude } = l
    if (!latitude || !longitude || !myLocation) {
      sorted.push(l)
      return
    }
    const dist =
      myLocation && latitude && longitude && round(distance([myLocation.lat, myLocation.lon], [latitude, longitude], { units: 'miles' }), 1) + ' mi'
    const withDistance = { ...l, dist }
    sorted.push(withDistance)
  })
  sorted = sort(sorted, (o) => parseFloat(o.dist))

  return (
    <div id="location-list">
      {sorted.map((site, index) => (
        <div key={index}>
          <VotingLocation site={site} myLocation={myLocation} />
          <div className="border"></div>
        </div>
      ))}
    </div>
  )
}

Locations.defaultProps = {
  myLocation: null,
}

Locations.propTypes = {
  locations: PropTypes.array.isRequired,
  myLocation: PropTypes.object,
}
