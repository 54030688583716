import React from 'react'
import PropTypes from 'prop-types'

function Overlay({ overlayInfo, htmlText, closeOverlay }) {
  //console.log(overlayInfo)
  return (
    <div id="overlay">
      <div className="centered-content">
        {overlayInfo.image && <img src={overlayInfo.image}></img>}
        {overlayInfo.failedState ? <h2 className="failed-title">{overlayInfo.text}</h2> : <h2>{overlayInfo.text}</h2>}
        {overlayInfo.subtext && <div className="subtext">{overlayInfo.subtext}</div>}
        {htmlText && <div dangerouslySetInnerHTML={{ __html: htmlText }} />}
        {overlayInfo.failedState && (
          <a href={overlayInfo.url} target="_blank" rel="noreferrer">
            More Info
          </a>
        )}
        <button onClick={closeOverlay} className="btn btn-primary btn-block">
          {overlayInfo.buttonText}
        </button>
      </div>
    </div>
  )
}

Overlay.propTypes = {
  overlayInfo: PropTypes.object.isRequired,
  closeOverlay: PropTypes.func.isRequired,
  htmlText: PropTypes.any,
}

export default Overlay
