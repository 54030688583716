import PropTypes from 'prop-types'
import { useEffect } from 'react'
import scrollToTop from '../../../lib/scrollToTop'

import LoadingSpinner from '../../../components/LoadingSpinner'
import ExternalLink from '../../ExternalLink'
import PageHeaderGoBack from '../../PageHeaderGoBack'
import stateNames from '../../../constants/stateNames'

function MoreDetails({ user, info, setNavTab, pending }) {
  const fullStateName = stateNames[user.state] || user.state

  const status = user.regStatus.status
  const message = user.regStatus.message
  const hideStateMessage = message === ''

  useEffect(() => {
    scrollToTop()
  }, [])

  const buttonRender = (text) => {
    return <button className="btn btn-primary">{text}</button>
  }

  const renderStatusMessage = (regStatus) => {
    switch (regStatus) {
      case 'pending':
        return <h1>Your voter registration in {fullStateName} is currently pending.</h1>
      case 'warn':
        return <h1>We found a potential issue tied to your voter registration in {fullStateName}.</h1>
      case 'no':
        return <h1>Bummer. We can’t find your voter registration in {fullStateName}.</h1>
      case 'fail':
        return <h1>Bummer. We can’t find your voter registration in {fullStateName}.</h1>
    }
  }

  if (pending) {
    return (
      <div id="voter-info-pending">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <>
      {user && info && (
        <div id="more-details">
          <PageHeaderGoBack title={'Voter Registration Status'} setNavTab={setNavTab} />

          <>
            {renderStatusMessage(user.regStatus.status)}
            {status === 'pending' && <p className="waiting-subtitle">We will keep checking and let you know when your application goes through.</p>}

            {hideStateMessage ? (
              ''
            ) : (
              <>
                <h3>What your state says:</h3>
                <p className="state-message">{message}</p>
              </>
            )}

            <h2>What you can do next:</h2>

            <h3>Try again with different information</h3>
            <p>
              Double check the information you submitted. Make sure you aren’t using a nickname. If your street has more than one name, try the other
              name.{' '}
            </p>
            <button onClick={() => setNavTab('check-registration')} className="btn btn-primary">
              Update Your Information
            </button>

            <h3>Check directly with your state</h3>
            <p>
              Your state will have the most up-to-date and accurate results. If you’ve recently registered at a new address you should check directly
              with your state.
            </p>
            <ExternalLink link={buttonRender('Check With Your State')} url={info.external_tool_verify_status.value} />
            <h3>Register to Vote</h3>
            <p>You can always re-register to vote if you’re not sure if you’re registered.</p>
            <ExternalLink link={buttonRender('Register to Vote')} url={info.external_tool_ovr.value} />
          </>
        </div>
      )}
    </>
  )
}

MoreDetails.propTypes = {
  user: PropTypes.object.isRequired,
  setNavTab: PropTypes.func.isRequired,
  info: PropTypes.object,
  pending: PropTypes.bool,
}

export default MoreDetails
