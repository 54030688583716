import { Form } from 'react-bootstrap'
import { useState } from 'react'
import firebase from 'firebase/app'

function VotingCheckbox() {
  const [didVote, setDidVote] = useState(false)
  const [didNotVote, setDidNotVote] = useState(false)
  const [willVote, setWillVote] = useState(false)
  const [willNotVote, setWillNotVote] = useState(false)
  const updateProfile = firebase.functions().httpsCallable('updateProfile')
  const [payload, setPayload] = useState([])

  const didSelect2016 = didVote || didNotVote
  const showButton = !(didSelect2016 && willVote)

  const submitSelections = async (selection) => {
    try {
      await updateProfile({
        campaign: 'vote2020',
        profile: {
          didVote2016: selection,
          willVote2020: true,
        },
      })
    } catch (e) {
      console.log('e', e)
    }
  }

  function handle2016Change(e, field) {
    // two values to default no check marks
    e.preventDefault()
    e.stopPropagation()
    switch (field) {
      case 1:
        setDidVote(true)
        setDidNotVote(false)
        setPayload(true)
        break
      case 2:
        setDidVote(false)
        setDidNotVote(true)
        setPayload(false)
        break
    }
  }

  function handle2020Change(e, field) {
    // two values to default no check marks
    e.preventDefault()
    e.stopPropagation()
    switch (field) {
      case 3:
        setWillVote(true)
        setWillNotVote(false)
        break
      case 4:
        setWillVote(false)
        setWillNotVote(true)
        break
    }
  }

  return (
    <div id="voting-checkbox">
      <div className="checkbox-wrapper">
        <h2>Did you vote in the 2016 election?</h2>
        <Form className="mt-6 checkbox-container">
          <div id="gtm-yes-2016-election" className="custom-control checkbox-item" onClick={(e) => handle2016Change(e, 1)}>
            <Form.Check custom type="checkbox" id="yes" label={'Yes'} checked={didVote} onChange={(e) => handle2016Change(e, 1)} />
          </div>
          <div className="custom-control checkbox-item" onClick={(e) => handle2016Change(e, 2)} id="gtm-no-2016-election">
            <Form.Check custom type="checkbox" id="no" label={'No'} checked={didNotVote} onChange={(e) => handle2016Change(e, 2)} />
          </div>
        </Form>
        <h2>Will you commit to vote early in 2020?</h2>
        <Form className="mt-6 checkbox-container">
          <div id="gtm-yes-2016-election" className="custom-control checkbox-item" onClick={(e) => handle2020Change(e, 3)}>
            <Form.Check custom type="checkbox" id="yes" label={'Yes'} checked={willVote} onChange={(e) => handle2020Change(e, 3)} />
          </div>
          <div className="custom-control checkbox-item" onClick={(e) => handle2020Change(e, 4)}>
            <Form.Check custom type="checkbox" id="no" label={'No'} checked={willNotVote} onChange={(e) => handle2020Change(e, 4)} />
          </div>
        </Form>
        <p>
          Your answers will remain anonymous, always. Landslide uses the results from this question to determine the cumulative number of new voters
          on the platform.
        </p>
        <button onClick={() => submitSelections(payload)} disabled={showButton} className="btn btn-primary btn-block">
          View My Power
        </button>
      </div>
    </div>
  )
}

export default VotingCheckbox
