import React from 'react'
import PropTypes from 'prop-types'

function ActionCard({ children }) {
  return (
    <div className="action-card">
      <div>
        <p className="next-step">Next step:</p>
        <div className="content">{children}</div>
      </div>
    </div>
  )
}

ActionCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default ActionCard
