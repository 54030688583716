import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getItem } from '../../lib/asyncStorage'

import firebase from 'firebase/app'
import { useRouter } from 'next/router'
import { logMsg } from '../../lib/err'

function CaptainImg({ copyVersion }) {
  const [captainInfo, setCaptainInfo] = useState()
  const [loading, setLoading] = useState(true)
  const [hasInvite, setHasInvite] = useState(false)
  const { query } = useRouter()
  useEffect(() => {
    let crewInvite = Object.prototype.hasOwnProperty.call(query, 'c') ? query.c : null
    const fetchCaptain = async () => {
      try {
        const getCaptainInfo = firebase.functions().httpsCallable('getCaptainInfo')

        const crewStorageRef = await getItem('crew')
        if (!crewInvite && crewStorageRef) {
          crewInvite = crewStorageRef
        }
        if (crewInvite) {
          setHasInvite(true)
          const { data } = await getCaptainInfo({ crew: crewInvite })
          setCaptainInfo(data)
        } else {
          // try refer invite
          let referInvite = Object.prototype.hasOwnProperty.call(query, 'r') ? query.r : null
          const referStorageRef = await getItem('refer')

          if (!referInvite && referStorageRef) {
            referInvite = referStorageRef
          }
          if (referInvite) {
            setHasInvite(true)
            const { data } = await getCaptainInfo({ refer: referInvite })
            setCaptainInfo(data)
          }
        }
      } catch (e) {
        logMsg('Failed to fetch captain info in intro')
      } finally {
        setLoading(false)
      }
    }
    fetchCaptain()
  }, [])

  const initial = captainInfo?.fname.charAt(0).toUpperCase() || ''

  return (
    <div id="captain-img">
      {!loading && captainInfo?.fname ? (
        <div id="has-captain">
          {captainInfo?.photoURL ? (
            <img src={captainInfo.photoURL} alt="captain profile" />
          ) : (
            <div className="initial">
              <p>{initial}</p>
            </div>
          )}
          {captainInfo?.fname && <p>{captainInfo.fname} invited you to join Landslide and vote early with them.</p>})
        </div>
      ) : (
        <div className={copyVersion === 1 ? 'no-crew' : 'no-crew-copy-2'} />
      )}
    </div>
  )
}

CaptainImg.propTypes = {
  copyVersion: PropTypes.number.isRequired,
}

export default CaptainImg
