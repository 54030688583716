import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Row, Form, Dropdown } from 'react-bootstrap'
import * as Yup from 'yup'
import phone from 'phone'
import { Formik } from 'formik'
import firebase from 'firebase/app'

import stateNames from '../../constants/stateNames'
import { ZIP_CODE_REGEX } from '../../globals'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import logErr from '../../lib/err'
import { useStore } from '../../hooks'
import { mergeUser } from '../../reducers/actions'
import { trimObj } from '../../lib/util'

// if (process.env.ENV !== "prod")
//   import fakeVoters from "../../constants/fakeVoters.json";

const stateArr = Object.entries(stateNames)
  .map((e) => e[0])
  .sort()

const formSchema = Yup.object().shape({
  fname: Yup.string().required('First name is required'),
  lname: Yup.string(),
  fnameLegal: Yup.string().required('First legal name is required'),
  lnameLegal: Yup.string().required('Last legal name is required'),
  phone: Yup.string().test('', 'Valid US Phone number is required', (value) => {
    const parsedPhone = phone(value || '')
    return parsedPhone.length === 2 && parsedPhone[1] === 'USA'
  }),
  zipcode: Yup.string().matches(ZIP_CODE_REGEX, 'Zip Code is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  email: Yup.string().email('Valid email address is required'),
  address1: Yup.string().required('Address is required'),
  address2: Yup.string(),
  dob: Yup.date().required('Date of birth is required'),
})

const INTITIAL_VALUES = {
  fname: '',
  lname: '',
  fnameLegal: '',
  lnameLegal: '',
  phone: '',
  zipcode: '',
  email: '',
  city: '',
  state: '',
  address1: '',
  address2: '',
  dob: '',
}

const parseValues = (user) => {
  const { address1, address2, fname, lname, fnameLegal, lnameLegal, email, phone, dob, zipcode, city, state } = user
  const newValues = {
    ...INTITIAL_VALUES,
    address1: address1 || '',
    address2: address2 || '',
    fname,
    lname,
    fnameLegal: fnameLegal || fname,
    lnameLegal: lnameLegal || lname,
    dob: dob ? format(addDays(new Date(dob), 1), 'yyyy-MM-dd') : '', // hacky way to add a day, but otherwise it falls back with the conversion to milliseconds
    zipcode: zipcode || '',
    city: city || '',
    state: state || '',
    phone,
    email,
  }
  return newValues
}
function UserFormLg({ user, isCheckRegistration, checkRegistration, checkPending, setNavTab }) {
  const [, dispatch] = useStore()
  const [formState, setFormState] = useState(parseValues(user))
  const [errorText, setErrorText] = useState('')
  const [pending, setPending] = useState(false)
  const [fakeVoters, setFakeVoters] = useState()
  const [userAddresses, setUserAddresses] = useState()

  const updateProfile = firebase.functions().httpsCallable('updateProfile')

  const submitForm = async (values) => {
    setErrorText('')
    setPending(true)
    let clonedValues = { ...values }
    const dob = new Date(values.dob).getTime()
    const parsedPhone = phone(values.phone || '')
    clonedValues.phone = parsedPhone[0]
    clonedValues.dob = dob
    clonedValues.isCheckRegistration = !!isCheckRegistration
    clonedValues = trimObj(clonedValues)
    if (!clonedValues.email) {
      delete clonedValues.email // can't submit empty string or null, so removing it
    }
    try {
      //optimistic update
      dispatch(mergeUser(clonedValues))
      // save to backend
      await updateProfile({
        campaign: 'vote2020',
        profile: clonedValues,
      })
    } catch (error) {
      logErr(error, 'extended user form upload error')
      setErrorText('There was an error: ' + (error.message || 'unknown'))
      return
    } finally {
      setPending(false)
    }
    if (isCheckRegistration) checkRegistration()
    else setNavTab('voter-info-profile')
  }
  useEffect(() => {
    const loadFakes = async () => {
      const fakes = await import('../../constants/fakeVoters.json')
      setFakeVoters(fakes.default)
      const addresses = await import('../../constants/addresses.json')
      const _addresses = []
      Object.entries(addresses.default).forEach(([, addrs]) => {
        addrs.forEach((addr) => {
          _addresses.push(addr)
        })
      })
      setUserAddresses(_addresses)
    }
    if (process.env.ENV === 'local' || process.env.ENV === 'develop' || process.env.ENV === 'qa') loadFakes()
  }, [])

  function compareVoters(a, b) {
    return (a['first_name'] + ' ' + a['last_name']).localeCompare(b['first_name'] + ' ' + b['last_name'])
  }

  return (
    <div id="user-form-lg">
      {fakeVoters && (
        <Dropdown>
          <Dropdown.Toggle>{process.env.ENV} users</Dropdown.Toggle>
          <Dropdown.Menu>
            {fakeVoters.sort(compareVoters).map((voter, index) => (
              <Dropdown.Item
                key={index}
                onClick={() => {
                  const newState = {
                    fname: voter.first_name,
                    lname: voter.last_name,
                    fnameLegal: voter.first_name,
                    lnameLegal: voter.last_name,
                    address1: voter.address,
                    city: voter.city,
                    state: voter.state,
                    zipcode: voter.zip,
                    dob: voter.birth_date,
                  }
                  setFormState({ ...formState, ...newState })
                }}
              >
                {voter.first_name} {voter.last_name} ({voter.state})
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
      {userAddresses && (
        <Dropdown>
          <Dropdown.Toggle>addresses</Dropdown.Toggle>
          <Dropdown.Menu>
            {userAddresses.map((addr, index) => {
              const address = addr.state + ': ' + `${addr.address1} ${addr.city} ${addr.state} ${addr.zipcode}`
              return (
                <Dropdown.Item
                  key={index}
                  onClick={() => {
                    setFormState({ ...formState, ...addr })
                  }}
                >
                  {address}
                </Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>
      )}
      <Formik
        initialValues={formState}
        onSubmit={(values) => {
          submitForm(values)
        }}
        validationSchema={formSchema}
        enableReinitialize={true}
      >
        {({ errors, touched, handleSubmit, handleChange, handleBlur, values }) => {
          return (
            <Form autoComplete="on" onSubmit={handleSubmit}>
              {!isCheckRegistration && (
                <>
                  <Form.Group className="custom-input">
                    <Form.Label htmlFor="fname" className="custom-label">
                      Preferred First Name
                    </Form.Label>
                    <Form.Control
                      name="fname"
                      placeholder={'First name'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      value={values.fname}
                    />
                    {touched.fname && errors.fname && <Form.Text>{errors.fname}</Form.Text>}
                  </Form.Group>
                  <Form.Group className="custom-input">
                    <Form.Label htmlFor="lname" className="custom-label">
                      Preferred Last Name
                    </Form.Label>
                    <Form.Control
                      name="lname"
                      placeholder={'Last name'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      value={values.lname}
                    />
                    {touched.lname && errors.lname && <Form.Text>{errors.lname}</Form.Text>}
                  </Form.Group>
                </>
              )}

              <Form.Group className="custom-input">
                <Form.Label htmlFor="fnameLegal" className="custom-label">
                  Legal First Name
                </Form.Label>
                <Form.Control
                  name="fnameLegal"
                  placeholder={'First Legal Name'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  value={values.fnameLegal}
                />
                {touched.fnameLegal && errors.fnameLegal && <Form.Text>{errors.fnameLegal}</Form.Text>}
              </Form.Group>
              <Form.Group className="custom-input">
                <Form.Label htmlFor="lnameLegal" className="custom-label">
                  Legal Last Name
                </Form.Label>
                <Form.Control
                  name="lnameLegal"
                  placeholder={'Last Legal Name'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  value={values.lnameLegal}
                />
                {touched.lnameLegal && errors.lnameLegal && <Form.Text>{errors.lnameLegal}</Form.Text>}
              </Form.Group>
              <Form.Group className="custom-input">
                <Form.Label htmlFor="phone" className="custom-label">
                  Phone Number
                </Form.Label>
                <Form.Control
                  name="phone"
                  placeholder={'Phone Number'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  value={values.phone}
                />
                {touched.phone && errors.phone && <Form.Text>{errors.phone}</Form.Text>}
              </Form.Group>
              <Form.Group className="custom-input">
                <Form.Label htmlFor="email" className="custom-label">
                  Email
                </Form.Label>
                <Form.Control
                  name="email"
                  placeholder={'Email Address'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  value={values.email}
                />
                {touched.email && errors.email && <Form.Text>{errors.email}</Form.Text>}
              </Form.Group>
              <Form.Group className="custom-input">
                <Form.Label htmlFor="zipcode" className="custom-label">
                  Zip Code Where You Plan To Vote
                </Form.Label>
                <Form.Control
                  name="zipcode"
                  placeholder={'Zip Code'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  value={values.zipcode}
                />
                {touched.zipcode && errors.zipcode && <Form.Text>{errors.zipcode}</Form.Text>}
              </Form.Group>
              <Row>
                <Col xs="9">
                  <Form.Group className="custom-input">
                    <Form.Label htmlFor="city" className="custom-label">
                      City
                    </Form.Label>
                    <Form.Control name="city" placeholder={'City'} onChange={handleChange} onBlur={handleBlur} type="text" value={values.city} />
                  </Form.Group>
                  {touched.city && errors.city && <Form.Text>{errors.city}</Form.Text>}
                </Col>
                <Col xs="3">
                  <Form.Group className="custom-input">
                    <Form.Label htmlFor="city" className="custom-label">
                      State
                    </Form.Label>
                    <Form.Control as="select" name="state" onChange={handleChange} id="state-select" value={values.state}>
                      {stateArr.map((s) => (
                        <option key={s}>{s}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="custom-input">
                <Form.Label htmlFor="address" className="custom-label">
                  Your Street Address
                </Form.Label>
                <Form.Control
                  name="address1"
                  placeholder={'Address 1'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  value={values.address1}
                />
                {touched.address1 && errors.address1 && <Form.Text>{errors.address1}</Form.Text>}
              </Form.Group>
              <Form.Group className="custom-input">
                <Form.Label htmlFor="address" className="custom-label">
                  Street Address 2 (optional)
                </Form.Label>
                <Form.Control
                  name="address2"
                  placeholder={'Address 2'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  value={values.address2}
                />
              </Form.Group>
              <Form.Group className="custom-input">
                <Form.Label htmlFor="dob" className="custom-label">
                  Date of Birth
                </Form.Label>
                <Form.Control name="dob" placeholder={'MM/DD/YY'} onChange={handleChange} onBlur={handleBlur} type="date" value={values.dob} />
                {touched.dob && errors.dob && <Form.Text>{errors.dob}</Form.Text>}
              </Form.Group>
              {errorText && <p className="error-text">{errorText}</p>}

              {isCheckRegistration && <p>This information will only be used to check your voter registration status. </p>}

              <Button block type="submit" disabled={pending || checkPending || !formSchema.isValidSync(values)}>
                {/* add waiting spinner here 
                as we check your registration */}
                {isCheckRegistration && checkPending
                  ? 'Checking'
                  : isCheckRegistration && !checkPending
                  ? 'Check Status'
                  : pending
                  ? 'Saving'
                  : 'Save Information'}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

UserFormLg.propTypes = {
  user: PropTypes.object.isRequired,
  checkRegistration: PropTypes.func,
  checkPending: PropTypes.bool,
  isCheckRegistration: PropTypes.bool,
  setNavTab: PropTypes.func.isRequired,
}

export default UserFormLg
