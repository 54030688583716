import React from 'react'
import PropTypes from 'prop-types'
const CrewIconActive = '/static/landslide/icons/bottomnav/Active/crew-active@4x.png'
const CrewIconPassive = '/static/landslide/icons/bottomnav/Inactive/crew-inactive@4x.png'
const PowerIconPassive = '/static/landslide/icons/bottomnav/Inactive/power-inactive@4x.png'
const PowerIconActive = '/static/landslide/icons/bottomnav/Active/power-active@4x.png'
const ProfileIconPassive = '/static/landslide/icons/bottomnav/Inactive/profile-inactive@4x.png'
const ProfileIconActive = '/static/landslide/icons/bottomnav/Active/profile-active@4x.png'
const ImpactIconActive = '/static/landslide/icons/bottomnav/Active/more-active@4x.png'
const ImpactIconInactive = '/static/landslide/icons/bottomnav/Inactive/more-inactive@4x.png'

//EXPERIMENTS
import { logSegEvent } from '../../lib/analytics'
import { BUTTON_CLICK } from '../../constants/analytics'
const experiment = 'dashboard'
const variant = '1'

function BottomNav({ navTab, setNavTab }) {
  //In local scope to reduce jsx complexity
  const fullClick = (type) => {
    setNavTab(type)
    logSegEvent(BUTTON_CLICK, {
      experiment,
      variant,
      tag: `dashboard-nav-btn-${type}`,
    })
  }

  if (navTab === 'check-registration' || navTab === 'more-details' || navTab === 'my-options') {
    return <></>
  } else {
    //console.log(JSON.stringify(process.env));

    return (
      <>
        <div id="bottom-nav">
          <div className="btn-wrapper">
            <button id="gtm-dashboard-crew-nav" onClick={() => fullClick('voter-info')}>
              <img
                className="sml-img"
                src={navTab.match(/voter-info/i) || navTab === 'state-details' || navTab === 'user-form' ? ProfileIconActive : ProfileIconPassive}
                alt="Profile Page Icon"
              />
              <p className={navTab.match(/voter-info/i) || navTab === 'state-details' || navTab === 'user-form' ? '' : 'muted voter-info'}>
                Voter Info
              </p>
            </button>
            <button id="gtm-dashboard-crew-nav" onClick={() => fullClick('crew')}>
              <img src={navTab === 'crew' ? CrewIconActive : CrewIconPassive} alt="Crew Page Icon" />
              <p className={navTab === 'crew' ? '' : 'muted'}>Crew</p>
            </button>
            <button id="gtm-dashboard-crew-nav" onClick={() => fullClick('power')}>
              <img className="sml-img" src={navTab === 'power' ? PowerIconActive : PowerIconPassive} alt="Power Page Icon" />
              <p className={navTab === 'power' ? '' : 'muted'}>Power</p>
            </button>
            <button className="more-nav" id="gtm-dashboard-crew-nav" onClick={() => fullClick('more')}>
              <img src={navTab !== 'more' ? ImpactIconInactive : ImpactIconActive} alt="More Page Icon" />
              <p className={navTab === 'more' ? '' : 'muted voter-info'}>More</p>
            </button>
          </div>
        </div>
      </>
    )
  }
}

BottomNav.propTypes = {
  navTab: PropTypes.string.isRequired,
  setNavTab: PropTypes.func.isRequired,
}

export default BottomNav
