import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { Button, Col, Row, Form, Dropdown } from 'react-bootstrap'
import * as Yup from 'yup'
import phone from 'phone'
import { Formik } from 'formik'
import firebase from 'firebase/app'
import { ZIP_CODE_REGEX } from '../../globals'
import { useAuthWithToken, useInviteURL } from '../../hooks'
import stateNames from '../../constants/stateNames'
import logErr from '../../lib/err'
import { getItem } from '../../lib/asyncStorage'
import { setItem, removeItem } from '../../lib/asyncStorage'
import { logSegEvent } from '../../lib/analytics'
import { BUTTON_CLICK } from '../../constants/analytics'
import { REFERRAL_REGEX, CREW_REGEX } from '../../globals'
import recommendationsInfo from '../../constants/recommendationsInfo'
import { trimObj } from '../../lib/util'

const VoteAmerica = '/static/landslide/images/VoteAmerica.png'
const GoogleApi = '/static/landslide/images/GoogleCivicApi.png'

const stateArr = Object.entries(stateNames)
  .map((e) => e[0])
  .sort()

const formSchema = Yup.object().shape({
  // fname: Yup.string().required('First name is required'),
  // lname: Yup.string(),
  fnameLegal: Yup.string().required('First legal name is required'),
  lnameLegal: Yup.string().required('Last legal name is required'),
  phone: Yup.string().test('', 'Valid US Phone number is required', (value) => {
    const parsedPhone = phone(value || '')
    return parsedPhone.length === 2 && parsedPhone[1] === 'USA'
  }),
  zipcode: Yup.string().matches(ZIP_CODE_REGEX, 'Zip Code is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  // email: Yup.string().email('Valid email address is required'),
  address1: Yup.string().required('Address is required'),
  address2: Yup.string(),
  dob: Yup.date().required('Date of birth is required'),
})

const INTITIAL_VALUES = {
  // fname: '',
  // lname: '',
  fnameLegal: '',
  lnameLegal: '',
  phone: '',
  zipcode: '',
  city: '',
  state: '',
  address1: '',
  address2: '',
  dob: '',
}
const experiment = 'join-page'
const variant = 2

function JoinFormV2({ preference, state, setView, zip }) {
  const [checked, setChecked] = useState(false)
  const [formState, setFormState] = useState({ ...INTITIAL_VALUES, zipcode: zip, state: state.abbr })
  const [errorText, setErrorText] = useState('')
  const [pending, setPending] = useState(false)
  const [fakeVoters, setFakeVoters] = useState()
  const [userAddresses, setUserAddresses] = useState()
  const [query, setPath] = useInviteURL()

  const authWithToken = useAuthWithToken()
  const router = useRouter()

  const joinAuthPhone = firebase.functions().httpsCallable('joinAuthPhone')

  const submitForm = async (values) => {
    setErrorText('')
    setPending(true)
    let clonedValues = { ...values }
    const dob = new Date(values.dob).getTime()
    const parsedPhone = phone(values.phone || '')
    clonedValues.phone = parsedPhone[0]
    clonedValues.dob = dob
    clonedValues.earlyVote = preference
    clonedValues.fname = values.fnameLegal
    clonedValues.lname = values.lnameLegal
    const refer = query.r || (await getItem('refer'))
    if (refer && REFERRAL_REGEX.test(refer)) {
      clonedValues = { ...clonedValues, refer }
    }
    const crew = query.c || (await getItem('crew'))
    if (crew && CREW_REGEX.test(crew)) {
      clonedValues = { ...clonedValues, crew }
    }
    clonedValues = trimObj(clonedValues)
    try {
      const { data } = await joinAuthPhone(clonedValues)
      setChecked(true)
      if (data && data.result === 'success') {
        try {
          await authWithToken(data.authToken)
          logSegEvent('User Join Succeeded', { experiment, variant })
        } catch (err) {
          logSegEvent('Join Auth Failed', { experiment, variant, tag: 1, data: { ...data, dob, parsedPhone, ...clonedValues, err } })
        }
        try {
          await removeItem('refer')
          await removeItem('crew')
          await removeItem('joinData')
          await setItem('member', true)
        } catch (e) {
          logErr(e, 'join storage error')
        }

        if (clonedValues.state === 'CA') {
          console.log('dskljfldskjflkasdjflkadsjflksaj')
          router.push('/dashboard#voter-info') // don't show <OverlayRegStatusV2> for CA user
          // setPath('/dashboard#voter-info') // don't show <OverlayRegStatusV2> for CA user
        } else {
          router.push('/dashboard#voter-info-status')
          // setPath('/dashboard#voter-info-status')
        }
      } else {
        logSegEvent('Join Auth Failed', { experiment, variant, tag: 2, data: { ...data, dob, parsedPhone, ...clonedValues } })
        setErrorText('Checking Registration failed')
      }
    } catch (error) {
      logSegEvent('Join Auth Failed', { experiment, variant, tag: 3, data: { dob, parsedPhone, ...clonedValues, error } })
      setErrorText(error.message || 'unknown')
    } finally {
      setPending(false)
    }
  }

  useEffect(() => {
    const loadFakes = async () => {
      const fakes = await import('../../constants/fakeVoters.json')
      setFakeVoters(fakes.default)
      const addresses = await import('../../constants/addresses.json')
      const _addresses = []
      Object.entries(addresses.default).forEach(([, addrs]) => {
        addrs.forEach((addr) => {
          _addresses.push(addr)
        })
      })
      setUserAddresses(_addresses)
    }
    if (process.env.ENV === 'local' || process.env.ENV === 'develop' || process.env.ENV === 'qa') loadFakes()
  }, [])

  function compareVoters(a, b) {
    return (a['first_name'] + ' ' + a['last_name']).localeCompare(b['first_name'] + ' ' + b['last_name'])
  }

  console.log('preference', preference)
  return (
    <div id="join-form-v2">
      <header>
        <div className="top-bar">
          <button className="back-button" onClick={() => setView('preference')}>
            <img id="gtm-page-header-go-back" src="/static/landslide/icons/arrow.png" alt="go back" />
          </button>
          <h1>
            You selected:
            <span>{recommendationsInfo[preference].title} </span>
          </h1>
        </div>
      </header>
      <div className="content">
        <p className="next">
          <b>Next, check (or double check!) your voter registration status and get early voting polling locations and hours closest to you.</b>
        </p>
        {fakeVoters && (
          <Dropdown>
            <Dropdown.Toggle>{process.env.ENV} users</Dropdown.Toggle>
            <Dropdown.Menu>
              {fakeVoters.sort(compareVoters).map((voter, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => {
                    //console.log('old values', formState, 'new values', voter)
                    const newState = {
                      fname: voter.first_name,
                      lname: voter.last_name,
                      fnameLegal: voter.first_name,
                      lnameLegal: voter.last_name,
                      address1: voter.address,
                      city: voter.city,
                      state: voter.state,
                      zipcode: voter.zip,
                      dob: voter.birth_date,
                    }
                    setFormState({ ...formState, ...newState })
                  }}
                >
                  {voter.first_name} {voter.last_name} ({voter.state})
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
        {userAddresses && (
          <Dropdown>
            <Dropdown.Toggle>addresses</Dropdown.Toggle>
            <Dropdown.Menu>
              {userAddresses.map((addr, index) => {
                const address = addr.state + ': ' + `${addr.address1} ${addr.city} ${addr.state} ${addr.zipcode}`
                return (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      setFormState({ ...formState, ...addr })
                    }}
                  >
                    {address})
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        )}
        <Formik
          initialValues={formState}
          onSubmit={(values) => {
            submitForm(values)
          }}
          validationSchema={formSchema}
          enableReinitialize={true}
        >
          {({ errors, touched, handleSubmit, handleChange, handleBlur, values }) => {
            return (
              <Form autoComplete="on" onSubmit={handleSubmit}>
                <Form.Group className="custom-input">
                  <Form.Label htmlFor="fnameLegal" className="custom-label">
                    Legal First Name
                  </Form.Label>
                  <Form.Control
                    name="fnameLegal"
                    placeholder={'First Legal Name'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.fnameLegal}
                  />
                  {touched.fnameLegal && errors.fnameLegal && <Form.Text>{errors.fnameLegal}</Form.Text>}
                </Form.Group>
                <Form.Group className="custom-input">
                  <Form.Label htmlFor="lnameLegal" className="custom-label">
                    Legal Last Name
                  </Form.Label>
                  <Form.Control
                    name="lnameLegal"
                    placeholder={'Last Legal Name'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.lnameLegal}
                  />
                  {touched.lnameLegal && errors.lnameLegal && <Form.Text>{errors.lnameLegal}</Form.Text>}
                </Form.Group>
                <Form.Group className="custom-input">
                  <Form.Label htmlFor="phone" className="custom-label">
                    Phone Number
                  </Form.Label>
                  <Form.Control
                    name="phone"
                    placeholder={'Phone Number'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.phone}
                  />
                  {touched.phone && errors.phone && <Form.Text>{errors.phone}</Form.Text>}
                </Form.Group>
                <Form.Group className="custom-input">
                  <Form.Label htmlFor="zipcode" className="custom-label">
                    Zip Code Where You Plan To Vote
                  </Form.Label>
                  <Form.Control
                    name="zipcode"
                    placeholder={'Zip Code'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.zipcode}
                  />
                  {touched.zipcode && errors.zipcode && <Form.Text>{errors.zipcode}</Form.Text>}
                </Form.Group>
                <Row>
                  <Col xs="9">
                    <Form.Group className="custom-input">
                      <Form.Label htmlFor="city" className="custom-label">
                        City
                      </Form.Label>
                      <Form.Control name="city" placeholder={'City'} onChange={handleChange} onBlur={handleBlur} type="text" value={values.city} />
                    </Form.Group>
                    {touched.city && errors.city && <Form.Text>{errors.city}</Form.Text>}
                  </Col>
                  <Col xs="3">
                    <Form.Group className="custom-input">
                      <Form.Label htmlFor="city" className="custom-label">
                        State
                      </Form.Label>
                      <Form.Control as="select" name="state" onChange={handleChange} id="state-select" value={values.state}>
                        {stateArr.map((s) => (
                          <option key={s}>{s}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="custom-input">
                  <Form.Label htmlFor="address" className="custom-label">
                    Your Street Address
                  </Form.Label>
                  <Form.Control
                    name="address1"
                    placeholder={'Address 1'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.address1}
                  />
                  {touched.address1 && errors.address1 && <Form.Text>{errors.address1}</Form.Text>}
                </Form.Group>
                <Form.Group className="custom-input">
                  <Form.Label htmlFor="address" className="custom-label">
                    Street Address 2 (optional)
                  </Form.Label>
                  <Form.Control
                    name="address2"
                    placeholder={'Address 2'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.address2}
                  />
                </Form.Group>
                <Form.Group className="custom-input">
                  <Form.Label htmlFor="dob" className="custom-label">
                    Date of Birth
                  </Form.Label>
                  <Form.Control name="dob" placeholder={'MM/DD/YY'} onChange={handleChange} onBlur={handleBlur} type="date" value={values.dob} />
                  {touched.dob && errors.dob && <Form.Text>{errors.dob}</Form.Text>}
                </Form.Group>
                {errorText && <p className="error-text">{errorText}</p>}
                {errorText === 'Account already exists' && (
                  <Link href="/login">
                    <Button variant="outline-primary" block>
                      Login to your Account
                    </Button>
                  </Link>
                )}
                {!checked && (
                  <Button block type="submit" disabled={pending || !formSchema.isValidSync(values)}>
                    {pending ? 'Checking' : 'Get My Voting Information'}
                  </Button>
                )}
              </Form>
            )
          }}
        </Formik>
        <p className="description">
          By clicking “Get My Voting Information”, you agree to Landslide’s <Link href="/tou?back=vote-early">Terms and Conditions </Link>
          and <Link href="/privacy?back=vote-early">Privacy Policy</Link>. All the data we collect is used to provide you the Landslide service. The
          data is never sold, and it will never be used to contact or advertise to your friends. Landslide will never call you, and we’ll only send
          you ~5-10 messages between now and the election. You can reply STOP to opt out at any time.
        </p>
        <div className="powered-by">
          <b>Powered by</b>
          <img src={VoteAmerica} alt="Vote America Logo" />
          <img src={GoogleApi} alt="Google Civic API Logo" />
        </div>
      </div>
    </div>
  )
}

JoinFormV2.propTypes = {
  preference: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  setView: PropTypes.func.isRequired,
  zip: PropTypes.string.isRequired,
}

export default JoinFormV2
