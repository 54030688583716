import { useEffect } from 'react'
import PropTypes from 'prop-types'
import scrollToTop from '../../../lib/scrollToTop'

import UserForm from '../UserFormLg'
import PageHeaderGoBack from '../../PageHeaderGoBack'
import useCheckReg from '../../../hooks/useCheckReg'

function CheckRegistrationForm({ setNavTab, user, info }) {
  const [{ RegSuccessOverlay, pending: checkPending }, checkRegistration] = useCheckReg({
    setNavTab,
    user,
    info,
    onClose: () => setNavTab('voter-info-profile'),
  })

  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <div id="check-registration-form">
      <PageHeaderGoBack title={'Check your registration status'} setNavTab={setNavTab} />
      <p>Complete the information below, be sure to use your legal name and the address tied to where you are voting.</p>
      <UserForm {...{ setNavTab, user, checkPending, checkRegistration, isCheckRegistration: true }} />
      <RegSuccessOverlay />
    </div>
  )
}

CheckRegistrationForm.propTypes = {
  setNavTab: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  immediate: PropTypes.bool,
}

export default CheckRegistrationForm
