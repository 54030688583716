import React from 'react'
import PropTypes from 'prop-types'
const ArrowUp = '/static/landslide/icons/arrow-up.png'
import parse from 'date-fns/parse'
import isAfter from 'date-fns/isAfter'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'

const isAfterToday = (str) => {
  if (!str) return null
  const d = parse(str, 'yyyy-MM-dd', new Date())
  const after = isAfter(new Date(), addDays(d, 1)) // add one day is the backend data is midnight, so any time in that day would be "after"
  return after ? null : format(d, 'MMM dd')
}

const vbmInfoText = (info) => {
  // INFO: not ready yet!
  // let vbmInfo = ''
  // if (info.vbm_universal?.value) {
  //   return 'Vote by mail is available universally'
  // }
  // if (info.vbm_no_excuse?.value === 'True') {
  //   return 'Vote by mail is available, no excuse needed'
  // }
  // return 'aaaa'
}

function ExtendedVotingInfo({ info, setShowMoreInfo, setView, state }) {
  console.log('info', info)
  const regByMail = isAfterToday(info.l2020_registration_deadline_by_mail_date?.value)
  const regByPerson = isAfterToday(info.l2020_registration_deadline_in_person_date?.value)
  const regOnline = isAfterToday(info.l2020_registration_deadline_online_date?.value)
  const registrationIsClosed = !regByMail && !regByPerson && !regOnline
  return (
    <div id="extended-voting-info">
      <ul>
        <li>
          <button onClick={() => setView('info')}> Important Dates and Resources </button>{' '}
        </li>
        {registrationIsClosed ? (
          <li>Voter registration in your state is closed </li>
        ) : (
          <>
            {regByMail && <li>Register by mail until {regByMail}</li>}
            {regByPerson && <li>Register in person until {regByPerson}</li>}
            {regOnline && <li>Register online until {regOnline}</li>}
          </>
        )}
        {info?.sdr_election_day && (
          <li>
            {info.sdr_election_day.value === 'True'
              ? `${state} offers same day voter registration`
              : `${state} does not offer same day voter registration`}{' '}
          </li>
        )}
        {vbmInfoText(info) && <li>{vbmInfoText(info)}</li>}
      </ul>
      <button onClick={() => setShowMoreInfo(false)}>
        See less <img src={ArrowUp} alt="see less info" />
      </button>
    </div>
  )
}

ExtendedVotingInfo.propTypes = {
  info: PropTypes.object.isRequired,
  setShowMoreInfo: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
}

export default ExtendedVotingInfo
