import React, { useEffect, useState } from 'react'
import { Image as Img, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import smsLink from 'sms-link'
import { renderCrew } from './dashboardHelper.js'
const PendingIcon = '/static/landslide/icons/PendingIcon.png'
//EXPERIMENTS
import { logSegEvent } from '../../lib/analytics'
import { BUTTON_CLICK } from '../../constants/analytics'
import ExternalLink from '../ExternalLink'
import { setItem, getItem, removeItem } from '../../lib/asyncStorage'
import NudgeWarning from './NudgeWarning'

const exp_status = 'crew-check-status'
const var_status = '1'
const exp_nudge = 'crew-nudge'
const var_nudge = '1'

import VotingStatus from './VotingStatus.jsx'

export default function MemberComponent({ member, isCaptain, isMe, isUpcrew, setNavTab, votingInfo, votingInfoByState }) {
  const [showNudgeWarning, setShowNudgeWarning] = useState(false)
  const [acceptedNudgeWarning, setAcceptedNudgeWarning] = useState()
  const hasFilledCrew = member.membersCount > 4
  const { state } = member
  const stateIsTrackingBallots = votingInfoByState && votingInfoByState[state] && !!votingInfoByState[state].external_tool_absentee_ballot_tracker
  const exclusiveVBM = votingInfoByState && votingInfoByState[state] && votingInfoByState[state].vbm_universal
  const allowsEarlyVoting = votingInfoByState && votingInfoByState[state] && votingInfoByState[state].l2020_early_voting_starts_date
  const earlyVotingStartsInPerson = votingInfoByState && votingInfoByState[state] && votingInfoByState[state].l2020_early_voting_starts_date
  const votingStartsByMail = votingInfoByState && votingInfoByState[state] && votingInfoByState[state].l2020_vbm_ballot_send_earliest_date // this is the date the voter should have the ballot in hand, voting should be open by mail

  const externalRegisterToVote = (votingInfoByState && votingInfoByState[state] && votingInfoByState[state].external_tool_ovr) || ''

  useEffect(() => {
    const asyncHelper = async () => {
      const accepted = await getItem('acceptedNudgeWarning')
      if (accepted === 'yes') {
        setAcceptedNudgeWarning(true)
      } else {
        setAcceptedNudgeWarning(false)
      }
    }
    asyncHelper()
  }, [])

  // determines if we are in the early voting window for in person
  const canVoteInPerson = () => {
    let d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    const currentDate = [year, month, day].join('-')
    return currentDate > earlyVotingStartsInPerson
  }

  // determines if we are in the voting window for VBM
  const canVoteByMail = () => {
    let d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    const currentDate = [year, month, day].join('-')
    return currentDate >= votingStartsByMail
  }

  // if returns null it means the person is ready to vote but voting window is not open => hide button
  const CTAText = (info) => {
    if (info?.isRegistered === 'no') {
      return ['Register', null]
    }
    if (info?.isRegistered === 'pending') {
      return ['Received?', null]
    }
    if (info?.isRegistered === 'yes' && (!info?.voteMethod || info?.voteMethod === 'notSure')) {
      return ['Confirm', 'confirm']
    }
    // IN PERSON
    if (info?.voteMethod === 'inPerson') {
      if (!info?.isEarlyVoting && allowsEarlyVoting) {
        return ['Select Date', 'select']
      } else {
        return canVoteInPerson() ? ['Did you Vote?', 'vote'] : [null, null]
      }
    }
    //  BY MAIL
    if (info?.voteMethod === 'byMailOrAbsentee') {
      if (info?.receivedBallot === 'yes') {
        return canVoteByMail() ? ['Did you vote?', 'vote'] : [null, null]
      }
      if (info?.isTrackingBallot === 'yes' || exclusiveVBM || !stateIsTrackingBallots) {
        return ['Received?', 'received']
      }
      if (info?.isTrackingBallot === 'no' || !info?.isTrackingBallot) {
        return ['Tracked?', 'tracked']
      }
    }
    return ['Confirm', null]
  }

  const [ctaText, ctaParam] = CTAText(member.voteInfo)

  const nudgeMember = async () => {
    if (!acceptedNudgeWarning) {
      setShowNudgeWarning(true)
    }
  }

  const confirmNudgeAction = async () => {
    setShowNudgeWarning(false)
    logSegEvent(BUTTON_CLICK, {
      experiment: exp_nudge,
      variant: var_nudge,
    })
    if (acceptedNudgeWarning) {
      await setItem('acceptedNudgeWarning', 'yes')
    } else {
      // remove cookie, so it defaults to true next time again
      await removeItem('acceptedNudgeWarning')
    }
  }

  const cancelNudgeAction = async () => {
    setShowNudgeWarning(false)
  }

  return (
    <div className="member-container">
      <div className="profile-pic-container ml-n1">
        {member.photoURL ? (
          <Img className="profile-pic" src={member.photoURL} alt="Profile Picture" />
        ) : (
          <div className="fallback-profile-pic">
            <Img src={PendingIcon} alt="Profile Picture" />
          </div>
        )}
      </div>
      <div className="s-container">
        <div className="member-row">
          <div className="name-row">
            <h3>
              {member.fname} {member.lname && member.lname[0]}
            </h3>
            {(isCaptain && (
              <div className="dashboard-title captain">
                <h4>Captain</h4>
              </div>
            )) ||
              ''}
            {(isMe && !isCaptain && (
              <div className="dashboard-title you">
                <h4 className="you-h4">You</h4>
              </div>
            )) ||
              ''}
          </div>
          {(isMe && member.voteInfo?.didVote2020 !== 'yes' && ctaText && (
            <Button
              id="gtm-check-status-btn"
              className="btn btn-primary check-status-btn"
              onClick={() => {
                logSegEvent(BUTTON_CLICK, {
                  experiment: exp_status,
                  variant: var_status,
                })
                setNavTab(`voter-info-profile${ctaParam ? '?section=' + ctaParam : ''}`)
              }}
            >
              {ctaText}
            </Button>
          )) ||
            (!isMe && !hasFilledCrew && !isUpcrew && !isCaptain && member.allowNudging && (
              <Button
                id="gtm-nudge-btn"
                href={
                  acceptedNudgeWarning
                    ? smsLink({
                        phone: member.phone,
                        body: `Hey ${member.fname}! Just giving you a little nudge to come back and get your Landslide going. We all know how high the stakes are for this election. \nhttps://landslide2020.org`,
                      })
                    : ''
                }
                onClick={() => {
                  nudgeMember()
                }}
                className="btn btn-primary nudge-btn"
              >
                Nudge
              </Button>
            ))}
        </div>

        <div className="crew-status">
          <p className="description">
            Crew Status:
            {renderCrew(member.membersCount)}
          </p>
        </div>
        {votingInfo && member && <VotingStatus member={member} votingInfo={votingInfo} votingInfoByState={votingInfoByState} />}
      </div>
      {showNudgeWarning && (
        <NudgeWarning
          confirmAction={confirmNudgeAction}
          cancelAction={cancelNudgeAction}
          member={member}
          setAcceptedNudgeWarning={setAcceptedNudgeWarning}
          acceptedNudgeWarning={acceptedNudgeWarning}
        />
      )}
    </div>
  )
}

MemberComponent.defaultProps = {
  isMe: false,
  isUpcrew: false,
  votingInfo: null,
  votingInfoByState: null,
}

MemberComponent.propTypes = {
  isCaptain: PropTypes.bool.isRequired,
  isMe: PropTypes.bool.isRequired,
  isUpcrew: PropTypes.bool.isRequired,
  member: PropTypes.object.isRequired,
  setNavTab: PropTypes.func.isRequired,
  votingInfo: PropTypes.object,
  votingInfoByState: PropTypes.object,
}
