import React from 'react'
import PropTypes from 'prop-types'
import useBodyLockScroll from '../hooks/useBodyLockScroll'

function ExternalLinkModal({ url, link, message, setExternalLinkModal, onClose }) {
  useBodyLockScroll()
  const redirect = () => {
    const destination = url
    setExternalLinkModal({
      url: '',
      link: '',
      message: '',
      modalOpen: false,
      onClose: null,
    })
    window.open(destination)
    if (onClose) onClose('redirect')
  }

  return (
    <div id="external-link-modal">
      <div id="modal">
        <h3>You're leaving Landslide2020.org</h3>
        <p>{message}</p>
        {/* <p>{url}</p> */}
        <div className="d-flex jcsb">
          <button
            className="btn"
            onClick={() => {
              setExternalLinkModal({
                url: '',
                link: '',
                message: '',
                modalOpen: false,
                onClose: null,
              })
              if (onClose) onClose('cancel')
            }}
          >
            Cancel
          </button>
          <button className="btn" onClick={redirect}>
            Continue
          </button>
        </div>
      </div>
    </div>
  )
}

ExternalLinkModal.propTypes = {
  url: PropTypes.string.isRequired,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  message: PropTypes.string.isRequired,
  setExternalLinkModal: PropTypes.func.isRequired,
  onClose: PropTypes.func,
}

export default ExternalLinkModal
