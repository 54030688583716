import PropTypes from 'prop-types'
import ExternalLink from '../../ExternalLink'

function NotRegisteredForm({ info, registerToVoteButtonClick, setNavTab, isMonitoringStatus, externalLinkButtonRender, user }) {
  const failedStatus = user.regStatus && user.regStatus.status === 'fail'
  const buttonRender = (text) => {
    return <button className="btn btn-primary">{text}</button>
  }

  const currentDate = () => {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    const d = new Date()
    return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`
  }

  return (
    <>
      {/* THIS NEEDS TO BE FIXED -> 
      if you aren't successfully registered on login through early-voting, 
      we need to render a check button */}
      {!isMonitoringStatus && (
        // TODO:
        // need to confirm that this onClick method is fired
        <ExternalLink
          link={externalLinkButtonRender('Register to Vote')}
          url={info.external_tool_ovr.value}
          onClick={() => registerToVoteButtonClick()}
          button={true}
        />
      )}

      {isMonitoringStatus && (
        <>
          {failedStatus ? (
            <div id="waiting-subtitle">
              <p>Check with your state to get your voter registration in order.</p>
              <ExternalLink link={buttonRender('Check With Your State')} url={info.external_tool_verify_status.value} />
            </div>
          ) : (
            <p id="waiting-subtitle">
              We will keep checking and let you know if there are any changes to your status. If you are sure of your registration status, you can
              manually change your answer above.
            </p>
          )}

          <p>
            We last checked your registration status on {currentDate()}. <a onClick={() => setNavTab('more-details')}>View your voter status</a>
          </p>
        </>
      )}
    </>
  )
}

NotRegisteredForm.propTypes = {
  user: PropTypes.object.isRequired,
  setNavTab: PropTypes.func.isRequired,
  registerToVoteButtonClick: PropTypes.func,
  externalLinkButtonRender: PropTypes.func,
  voteInfo: PropTypes.object,
  info: PropTypes.object,
  isMonitoringStatus: PropTypes.bool,
}

export default NotRegisteredForm
