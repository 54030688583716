import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Image, Button, Spinner } from 'react-bootstrap'
import firebase from 'firebase/app'
import logErr from '../../lib/err'

import { VOTE_2020_CAMPAIGN } from '../../constants/posse'

const User = '/static/landslide/icons/User.png'

export default function InviteeComponent({ invitee, myUID, myPID }) {
  const [pendingConfirm, setPendingConfirm] = useState(false)
  const [pendingDeny, setPendingDeny] = useState(false)
  const confirmMember = firebase.functions().httpsCallable('confirmMember')

  const confirmInvitee = async () => {
    // console.log('CONFIRM MEMBER', invitee)
    setPendingConfirm(true)
    try {
      await confirmMember({
        action: 'confirm',
        uid: myUID,
        crewID: myPID,
        invUID: invitee.uid,
        campaign: VOTE_2020_CAMPAIGN,
      })
    } catch (e) {
      logErr(e, 'accept invite error')
    } finally {
      setPendingConfirm(false)
    }
  }

  const denyInvitee = async () => {
    setPendingDeny(true)
    try {
      setPendingDeny(true)
      await confirmMember({
        action: 'cancel',
        uid: myUID,
        crewID: myPID,
        invUID: invitee.uid,
        campaign: VOTE_2020_CAMPAIGN,
      })
    } catch (e) {
      logErr(e, 'deny invite error')
    } finally {
      setPendingDeny(false)
    }
  }

  return (
    <div id="invitee" className="member-container">
      <div className="profile-pic-container ml-n1">
        {invitee.photoURL ? (
          <Image className="profile-pic" src={invitee.photoURL} alt="Profile Picture" />
        ) : (
          <div className="fallback-profile-pic">
            <Image src={User} alt="Profile Picture" />
          </div>
        )}
      </div>
      <div className="s-container">
        <div className="member-row">
          <h3>
            {invitee.fname} {invitee.lname && invitee.lname[0]}
          </h3>
          <div className="action-btns">
            <Button id="gtm-deny-member-btn" variant="outline-primary" onClick={denyInvitee}>
              {pendingDeny ? <Spinner animation="border" size="sm" /> : 'Deny'}
            </Button>
            <Button id="gtm-confirm-member-btn" className="btn btn-primary " onClick={confirmInvitee}>
              {pendingConfirm ? <Spinner animation="border" size="sm" /> : 'Accept'}
            </Button>
          </div>
        </div>
        <div className="crew-status">
          <p className="description">Accepted your invite</p>
        </div>
      </div>
    </div>
  )
}

InviteeComponent.propTypes = {
  invitee: PropTypes.object.isRequired,
  myUID: PropTypes.string.isRequired,
  myPID: PropTypes.string,
}
