import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useModal, useStore, useCloudFn, useCloudFn2, useFunctions } from '../hooks'
import { logPageView } from '../lib/analytics'
import { getStateFromZip } from '../lib/getState'
import { isMobileDevice } from '../lib/sharing'
import * as Sentry from '@sentry/node'

import Layout from '../components/Layout'
import Locations from '../components/vote-early/Locations'
import DashboardHeader from '../components/dashboard/DashboardHeader'
import BottomNav from '../components/dashboard/BottomNav'
import CrewPage from '../components/dashboard/CrewPage'
import PowerSection from '../components/dashboard/PowerSection'
import VoterInfo from '../components/dashboard/VoterInfo/VoterInfo'
import StateDetails from '../components/dashboard/VoterInfo/StateDetails'
import MoreDetails from '../components/dashboard/VoterInfo/MoreDetails'
import MoreSection from '../components/dashboard/MoreSection'
import NeedsToRecheckDrawer from '../components/dashboard/NeedsToRecheckDrawer'
import EditUserInfo from '../components/dashboard/VoterInfo/EditUserInfo'
import CheckRegistrationForm from '../components/dashboard/VoterInfo/CheckRegistrationForm'
import ExternalLinkModal from '../components/ExternalLinkModal'
import MyOptions from '../components/vote-early/MyOptions'

const INITTIAL_EXTERNAL_LINK_CONTEXT = {
  modalOpen: false,
  link: '',
  url: '',
  message: '',
}

export const ExternalLinkContext = React.createContext()

// icons
const getAnchor = (url) => {
  const urlParts = url.split('dashboard#')
  return urlParts.length > 1 ? urlParts[1] : null
}

export default function Dashboard() {
  const [navTab, setNavTab] = useState('') // wait to route until after the anchor target has been handled
  const [usedLocationHash, setUsedLocationHash] = useState(false) // used to remember is Section was set by means of the URL hash
  const [externalLinkModal, setExternalLinkModal] = useState(INITTIAL_EXTERNAL_LINK_CONTEXT)
  const [votingInfoByState, setVotingInfoByState] = useState(null)
  const [needsToRecheck, setNeedsToRecheck] = useState(false) // flag on me.user.voteInfo if the user changes address
  const [locations, setLocations] = useState([])
  const [, , toggleModal] = useModal()
  const [{ pending: civicPending }, getCivicInfo] = useCloudFn2('getCivicInfo')
  const router = useRouter()
  const [{ me }, dispatch] = useStore()
  const { user } = me

  const [{ pending, value: votingInfo }, getStateInformation] = useCloudFn('getStateInformation')
  const fns = useFunctions()

  const powerReferCode = user && user.refer

  const getInfoForAllStates = async () => {
    const stateInformation = fns && fns.httpsCallable('getStateInformation')
    try {
      const { data } = await stateInformation({ crew: true })
      setVotingInfoByState(data)
    } catch (e) {
      console.log('e', e)
    }
  }

  const handleLocationsRender = (locationsGroup) => {
    const earlySites = user.civicInfo.earlyVoteSites
    const dropSites = user.civicInfo.dropOffLocations
    if (locationsGroup === 'earlySites') {
      setLocations(earlySites)
    } else if (locationsGroup === 'dropSites') {
      setLocations(dropSites)
    }
    setNavTab('locations')
  }

  useEffect(() => {
    const asyncHelper = async () => {
      try {
        if (user?.zipcode) {
          const res = await getCivicInfo({})
        }
      } catch (e) {
        console.log('getCivicInfo Error', e)
      }
    }
    asyncHelper()
  }, [user?.zipcode])

  useEffect(() => {
    if (!user) return
    if (!votingInfo) {
      const fallbackState = getStateFromZip(user.zipcode).abbr
      getStateInformation({ state: user?.state || fallbackState })
      getInfoForAllStates()
    }
  }, [user, navTab])

  useEffect(() => {
    // refetch stateInformation if the state the user votes in changes, doesn't get triggered on first load
    if (votingInfo) {
      const fallbackState = getStateFromZip(user.zipcode).abbr
      getStateInformation({ state: user?.state || fallbackState })
    }
  }, [user?.state])

  useEffect(() => {
    // Check url on mount if there is a dashboard achor tag: #crew, #power, #voter-info
    const target = getAnchor(router.asPath)
    // if (target) setNavTab(target)
    // else setNavTab('crew')
    if (target === 'crew') {
      setNavTab('crew')
    } else if (target === 'power') {
      setNavTab('power')
    } else if (target === 'voter-info') {
      setNavTab('voter-info')
    } else if (target === 'voter-info-state-guide') {
      setNavTab('voter-info-state-guide-info')
    } else if (target === 'voter-info-profile') {
      setNavTab('voter-info-profile')
    } else if (target === 'voter-info-early-voting') {
      setNavTab('voter-info-early-voting')
    } else if (target === 'voter-info-status') {
      setNavTab('voter-info-status')
    } else if (target === 'state-details') {
      setNavTab('state-details')
    } else if (target === 'edit-info') {
      setNavTab('edit-info')
    } else if (target === 'check-registration') {
      setNavTab('check-registration')
    } else if (target === 'more-details') {
      setNavTab('more-details')
    } else if (target === 'more') {
      setNavTab('more')
    } else {
      setNavTab('voter-info')
    }
    setUsedLocationHash(true)
  }, [])

  useEffect(() => {
    if (me?.user?.voteInfo?.needsToRecheck) {
      setNeedsToRecheck(true)
    }
    if (navTab && usedLocationHash) {
      logPageView(`dashboard-${navTab}`)
    }
    // this might cause a loop or rerender?
    router.push(`/dashboard#${navTab}`) // Keeping URL in sync with anchor tag setting
  }, [navTab])

  useEffect(() => {
    if (me?.user && !me?.loading) {
      Sentry.configureScope(function (scope) {
        scope.setUser({
          email: me.user.email,
          id: me.user.uid,
        })
        scope.setExtra(`phone`, me.user.phone)
        scope.setExtra(`fname`, me.user.fname)
        scope.setExtra(`lname`, me.user.lname)
      })
    }
  }, [me])

  useEffect(() => {
    if (me?.user?.voteInfo?.needsToRecheck) {
      setNeedsToRecheck(true)
    }
  }, [me?.user?.voteInfo?.needsToRecheck])

  if (!isMobileDevice) {
    return null
  }

  return (
    <Layout fullScreen title={`Dashboard - ${navTab}`}>
      <ExternalLinkContext.Provider value={{ ...externalLinkModal, setExternalLinkModal }}>
        <DashboardHeader navTab={navTab} setNavTab={setNavTab} state={user?.state} />
        {user && (
          <div id="dashboard">
            {navTab === 'power' && <PowerSection user={user} />}
            {navTab === 'crew' && (
              <CrewPage
                toggleModal={toggleModal}
                user={user}
                setNavTab={setNavTab}
                votingInfo={votingInfo} // full info for the user
                votingInfoByState={votingInfoByState} // selected info for all states necessary for status messages
              />
            )}
            {navTab.match(/voter-info/i) && (
              <VoterInfo
                civicPending={civicPending}
                navTab={navTab}
                user={user}
                setNavTab={setNavTab}
                info={votingInfo}
                pending={pending}
                powerReferCode={powerReferCode}
                handleLocationsRender={handleLocationsRender}
              />
            )}
            {navTab === 'user-form' && <EditUserInfo info={votingInfo} pending={pending} user={user} setNavTab={setNavTab} />}
            {navTab === 'state-details' && <StateDetails info={votingInfo} pending={pending} />}
            {navTab === 'check-registration' && <CheckRegistrationForm setNavTab={setNavTab} user={user} info={votingInfo} />}
            {navTab === 'more-details' && <MoreDetails setNavTab={setNavTab} info={votingInfo} pending={pending} user={user} />}
            {navTab === 'more' && <MoreSection user={user} />}
            {navTab === 'locations' && <Locations locations={locations} myLocation={user.location} />}
            {navTab === 'my-options' && <MyOptions isInapp={true} info={votingInfo} goBack={() => setNavTab('voter-info-early-voting')} />}
          </div>
        )}
        <BottomNav navTab={navTab} setNavTab={setNavTab} />

        {externalLinkModal.modalOpen && <ExternalLinkModal {...externalLinkModal} setExternalLinkModal={setExternalLinkModal} />}
        {needsToRecheck && <NeedsToRecheckDrawer setNavTab={setNavTab} setNeedsToRecheck={setNeedsToRecheck} user={user} info={votingInfo} />}
      </ExternalLinkContext.Provider>
    </Layout>
  )
}
