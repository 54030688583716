import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import firebase from 'firebase/app'
import { Button, Form } from 'react-bootstrap'
import { getStateFromZip } from '../../lib/getState'
import { logPageView, logSegIdentity, logSegEvent, logSegPage } from '../../lib/analytics'
import { BUTTON_CLICK } from '../../constants/analytics'
import { isMobileDevice } from '../../lib/sharing'
const biden = '/static/landslide/images/hipster-biden-sm.png'
const trump = '/static/landslide/images/hipster-trump-sm.png'
import CaptainImg from './CaptainImg'
import NonPartisanCopy from './NonPartisanCopy'

const experiment = 'desktop-zip-page'

function EnterZip({ flashScreen, nonPartisan, setState, setInfo, setView, zip, setZip }) {
  //const getStateInformation = !flashScreen && firebase.functions().httpsCallable('getStateInformation')
  const [pending, setPending] = useState(false)
  const [copyVersion] = useState(Math.round(Math.random()) + 1)

  useEffect(() => {
    if (!flashScreen) {
      if (nonPartisan) {
        logPageView(`landing-non-partisan`)
        logSegPage(`landing-non-partisan`)
      } else {
        logPageView(`landing-${copyVersion}`)
        logSegPage(`landing-${copyVersion}`)
      }
    }
  }, [])

  const handleZipSubmit = async (e) => {
    setPending(true)
    e.preventDefault()
    e.stopPropagation()
    try {
      const getStateInformation = firebase.functions().httpsCallable('getStateInformation')
      const st = getStateFromZip(parseInt(zip))
      setState(st)
      const { data } = await getStateInformation({ state: st.abbr })
      logSegIdentity(null, { zip, landingCopyVersion: copyVersion })
      logSegEvent(BUTTON_CLICK, { experiment, variant: nonPartisan ? `landing-zip-non-partisan` : `landing-zip-${copyVersion}` })
      setInfo(data)
      setView('preference')
    } catch (e) {
      console.log('e', e)
    } finally {
      setPending(false)
    }
  }

  return (
    <div id="enter-zip">
      <div>
        <header>
          <h1>Landslide</h1>
          {!flashScreen && !nonPartisan && <CaptainImg copyVersion={copyVersion} />}
          {nonPartisan ? (
            <NonPartisanCopy />
          ) : copyVersion == 1 ? (
            <div id="copy-1">
              <h2>Trump is trying to steal the election.</h2>
              <h2 className="blue">Voting early or in person will stop him.</h2>
            </div>
          ) : (
            <div id="copy-2">
              <h2>
                <span>Vote early.</span> Avoid the lines.
              </h2>
              <h2>Make sure your vote is counted by Election Night.</h2>
              <h3>Get early voting options for your state</h3>
              <p>+ early voting rules</p>
              <p>+ how & when to vote by mail</p>
            </div>
          )}
        </header>
        <main>
          <Form onSubmit={handleZipSubmit}>
            <Form.Group>
              <Form.Control
                autoFocus
                className="zip-input"
                name="zipcode"
                placeholder={'ENTER YOUR ZIP CODE'}
                onChange={(e) => setZip(e.currentTarget.value)}
                type="number"
                value={zip}
              />
            </Form.Group>
            <Button id="zip-btn" block type="submit">
              {pending ? 'Checking' : 'See Your Best Voting Options'}
            </Button>
          </Form>
        </main>
      </div>
      <div>
        {!nonPartisan && (
          <div className="image-wrapper">
            <img className="biden" src={biden} alt="Hipster Biden" />
            <img className="trump" src={trump} alt="Hipster Trump" />
          </div>
        )}
        <footer>
          <p>Paid for by Landslide PAC</p>
          <div>
            <a href="https://secure.actblue.com/donate/jointhelandslide" target="_blank" rel="noreferrer">
              DONATE
            </a>
            {isMobileDevice && (
              <Link
                href={{
                  pathname: '/login',
                }}
              >
                LOGIN
              </Link>
            )}
            <Link
              href={{
                pathname: '/faq',
                query: { back: 'vote-early' },
              }}
            >
              FAQ
            </Link>
            <Link
              href={{
                pathname: '/privacy',
                query: { back: 'vote-early' },
              }}
            >
              PRIVACY
            </Link>
            <Link
              href={{
                pathname: '/tou',
                query: { back: 'vote-early' },
              }}
            >
              TERMS
            </Link>
          </div>
        </footer>
      </div>
    </div>
  )
}

EnterZip.defaultProps = {
  zip: ' ',
  flashScreen: false,
  nonPartisan: false,
}

EnterZip.propTypes = {
  flashScreen: PropTypes.bool,
  setState: PropTypes.func.isRequired,
  setInfo: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
  nonPartisan: PropTypes.bool,
  zip: PropTypes.string,
  setZip: PropTypes.func.isRequired,
}

export default EnterZip
