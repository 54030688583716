import React from 'react'
import PropTypes from 'prop-types'

function VoterInfoTabs({ navTab, setNavTab }) {
  return (
    <div id="voter-info-tabs">
      <button
        className={navTab === 'voter-info' || navTab === 'voter-info-early-voting' ? 'active-btn' : ''}
        id="early-voting-tab"
        onClick={() => setNavTab('voter-info-early-voting')}
      >
        <p className={navTab === 'voter-info' || navTab === 'voter-info-early-voting' ? '' : 'muted'}>Early Voting Guide</p>
      </button>
      <button className={navTab.match(/voter-info-profile/i) ? 'active-btn' : ''} id="voter-info-tab" onClick={() => setNavTab('voter-info-profile')}>
        <p className={navTab.match(/voter-info-profile/i) ? '' : 'muted'}>Your Info</p>
      </button>
      <button
        className={navTab === 'voter-info-state-guide' ? 'active-btn' : ''}
        id="state-guide-tab"
        onClick={() => setNavTab('voter-info-state-guide')}
      >
        <p className={navTab === 'voter-info-state-guide' ? '' : 'muted'}>State Guide</p>
      </button>
    </div>
  )
}

VoterInfoTabs.propTypes = {
  navTab: PropTypes.string.isRequired,
  setNavTab: PropTypes.func.isRequired,
}

export default VoterInfoTabs
