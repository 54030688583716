import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import stateNames from '../../../constants/stateNames'
import recommendationsInfo from '../../../constants/recommendationsInfo'
import { getShortDate, shortedDateSpan } from '../../../lib/dates'

import LoadingSpinner from '../../../components/LoadingSpinner'
import Recommendation from '../../vote-early/Recommendation'
const VoteAmerica = '/static/landslide/images/VoteAmerica.png'
const GoogleApi = '/static/landslide/images/GoogleCivicApi.png'

export default function EarlyVoting({ user, info, setNavTab, handleLocationsRender, civicPending }) {
  const fullStateName = stateNames[user.state] || user.state
  const [recommendations, setRecommendations] = useState([])
  const [openRecommendation, setOpenRecommendation] = useState(0)

  const civicInfo = user.civicInfo

  const earlyVoteStartDate = getShortDate(info.l2020_early_voting_starts_date.value)
  const earlyVoteEndDate = getShortDate(info.l2020_early_voting_ends_date.value)
  const idRequirementsInPerson = info.id_requirements_in_person_voting.value
  const idRequirementsVBM = info.id_requirements_vbm.value
  const ballotSignatureRules = info.vbm_warnings.value

  const dropSites = civicInfo && civicInfo.dropOffLocations
  const earlySites = civicInfo && civicInfo.earlyVoteSites
  const pollingLocations = civicInfo && civicInfo.pollingLocations
  const closestDropSite = (dropSites && dropSites[0]) || null
  const closestEarlySite = (earlySites && earlySites[0]) || null
  const closestPollingLocation = (pollingLocations && pollingLocations[0]) || null

  useEffect(() => {
    let recommendationsList = []

    info.l2020_voting_options.forEach((key, index) => {
      const rec = recommendationsInfo[key]
      rec.rank = index + 1
      rec.key = key

      let updatedWithDynamicData

      switch (key) {
        case 'vbmMailEP':
          updatedWithDynamicData = {
            ...rec,
            ballotRules: ballotSignatureRules,
            idText: idRequirementsVBM,
          }
          break
        case 'vbmMail':
          updatedWithDynamicData = {
            ...rec,
            ballotRules: ballotSignatureRules,
            idText: idRequirementsVBM,
          }
          break
        case 'vbmDrop':
          updatedWithDynamicData = {
            ...rec,
            ballotRules: ballotSignatureRules,
            idText: idRequirementsVBM,
            closestSite: closestDropSite,
          }
          break
        case 'vbmDropEP':
          updatedWithDynamicData = {
            ...rec,
            ballotRules: ballotSignatureRules,
            idText: idRequirementsVBM,
            closestSite: closestDropSite,
          }
          break
        case 'vipED':
          updatedWithDynamicData = {
            ...rec,
            idText: idRequirementsInPerson,
            closestSite: closestPollingLocation,
          }
          break
        case 'vipEarly':
          updatedWithDynamicData = {
            ...rec,
            idText: idRequirementsInPerson,
            closestSite: closestEarlySite,
          }
          break
      }
      if (key === user.earlyVote) {
        recommendationsList.unshift(updatedWithDynamicData)
      } else {
        recommendationsList.push(updatedWithDynamicData)
      }
    })

    setRecommendations(recommendationsList)
  }, [])

  if (!civicInfo) {
    return (
      <div id="voter-info-pending">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <>
      {civicInfo && (
        <div id="early-voting">
          <header>
            {earlyVoteStartDate && earlyVoteEndDate ? (
              <h2>
                Your Early Voting Days in <br />
                {fullStateName === 'District Of Columbia' ? 'Washington D.C.' : `${fullStateName}`} are{' '}
                {shortedDateSpan(earlyVoteStartDate, earlyVoteEndDate)}
              </h2>
            ) : (
              <h2>Our Recommendations for {fullStateName}</h2>
            )}
            <a onClick={() => setNavTab('voter-info-state-guide')}>View Important Dates and Resources</a>
          </header>
          <p id="options-intro">
            Landslide's rankings are based on your state’s unique voting rules. Our goal is to help get your vote counted on or before Election Night.
          </p>
          {recommendations.length &&
            recommendations.map((recommendation, index) => {
              return (
                <Recommendation
                  key={recommendation.key}
                  index={index}
                  setOpen={setOpenRecommendation}
                  handleLocationsRender={handleLocationsRender}
                  user={user}
                  stateInfo={info}
                  recommendation={recommendation}
                  isOpen={index === openRecommendation}
                  setNavTab={setNavTab}
                />
              )
            })}
            <>
              <div className="donation-card">
                <h2>Landslide is a small non-profit. Can you pitch in $3 to help us fight the good fight?</h2>
                <a href="https://secure.actblue.com/donate/jointhelandslide" target="_blank" rel="noreferrer">
                  <button className="btn btn-primary btn-block">
                    Donate
                  </button>
                </a>
              </div>
              <div className="powered-by">
                <b>Powered by</b>
                <img src={VoteAmerica} alt="Vote America Logo" />
                <img src={GoogleApi} alt="Google Civic API Logo" />
              </div>
            </>
        </div>
      )}
    </>
  )
}

EarlyVoting.propTypes = {
  info: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setNavTab: PropTypes.func.isRequired,
  handleLocationsRender: PropTypes.func.isRequired,
}
