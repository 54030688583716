import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import VotingLocation from '../vote-early/VotingLocation'
import ExternalLink from '../ExternalLink'
import ReactMarkdown from 'react-markdown'
import { Link as ScrollLink } from 'react-scroll'
import scrollToTop from '../../lib/scrollToTop'

function Recommendation({
  index,
  setOpen,
  setNavTab,
  handleLocationsRender,
  user,
  stateInfo,
  recommendation,
  isOpen,
  isExternal = false,
  isMobileDevice,
  onSelect,
}) {
  const {
    key,
    rank,
    title,
    doThisIf,
    idText,
    idTitle,
    ballotRules,
    ballotRulesTitle,
    closestSite,
    locationsTitle,
    checkMobile,
    checkDesktop,
  } = recommendation

  const isVIP = key.match(/vip/i)
  const isVIPEarly = key === 'vipEarly'
  const vbmMail = key.match(/vbmMail/i)
  const isVBMDrop = key.match(/vbmDrop/i)
  const noDataText = "We don't have location info for your state, but your state might..."
  const linkText = 'You can double check here.'
  const isInternal = !isExternal
  const showLocations = isInternal && (isVBMDrop || isVIP)
  const showBallotRules = isInternal && ballotRulesTitle
  const showIDInfo = isInternal && idTitle

  return (
    <>
      {isOpen && <div id="scroll" />}
      <ScrollLink to={index === 0 ? '' : 'scroll'} onClick={index === 0 ? () => scrollToTop() : null}>
        <div id="recommendation">
          <header onClick={() => setOpen(index)} className={vbmMail ? 'vbm-header' : ''}>
            <h2>{title}</h2>
            {vbmMail && <div className="subtitle">Put your ballot in the mail by Oct 23</div>}
            <div id={rank === 1 ? 'triangle-active' : 'triangle-passive'}>
              <p>{rank}</p>
            </div>
          </header>
          {isOpen && (
            <div className="content">
              <div className="section">
                <h2>We recommend this because:</h2>
                <ul>
                  {doThisIf.map((d, i) => (
                    <li key={i}>{d}</li>
                  ))}
                </ul>
              </div>
              {isVIP && (
                <div className="section">
                  <h2>What should I do if I want to vote in person but:</h2>
                  <ul className="vbm-vip-options-ul">
                    <li>I’m registered to vote by mail / absentee</li>
                    <li>I will receive a mail in ballot</li>
                    <li>I’ve already received my ballot</li>
                  </ul>
                  <a onClick={() => setNavTab('my-options')} className="vbm-vip-options-link">
                    See my options
                  </a>
                </div>
              )}

              {isExternal && (
                <>
                  <div className="section">
                    {isMobileDevice ? (
                      <>
                        <p>{checkMobile}</p>
                        <Button
                          block
                          onClick={() => {
                            onSelect(key)
                          }}
                        >
                          Next
                        </Button>
                      </>
                    ) : (
                      <p>
                        {checkDesktop}
                        <a href="https://landslide2020.org"> landslide2020.org</a> on your mobile browser.
                      </p>
                    )}
                  </div>
                </>
              )}

              {showLocations && (
                <div id={closestSite ? 'location-container' : ''} className="section">
                  <h2>{locationsTitle}</h2>
                  {closestSite ? (
                    <div id="location">
                      <VotingLocation key={index} site={closestSite} myLocation={user.location} />
                      {(isVIPEarly || isVBMDrop) && (
                        <a onClick={() => handleLocationsRender(isVBMDrop ? 'dropSites' : 'earlySites')}>See all locations for my area</a>
                      )}
                    </div>
                  ) : (
                    <>
                      <p className="noData">{noDataText}</p>
                      <ExternalLink link={linkText} url={stateInfo.sos_election_website?.value} />
                    </>
                  )}
                </div>
              )}

              {showBallotRules && (
                <div className="section">
                  <h2>{ballotRulesTitle}</h2>
                  <div className="info">
                    <ReactMarkdown source={ballotRules} />
                  </div>
                </div>
              )}

              {showIDInfo && (
                <div className="section">
                  <h2>{idTitle}</h2>
                  <div className="info">
                    <ReactMarkdown source={idText} />
                  </div>
                </div>
              )}
            </div>
          )}
          {!isOpen && (
            <h4
              onClick={() => {
                setOpen(index)
              }}
              className="see-more"
            >
              See more
            </h4>
          )}
        </div>
      </ScrollLink>
    </>
  )
}

Recommendation.propTypes = {
  index: PropTypes.number.isRequired,
  stateInfo: PropTypes.object.isRequired,
  recommendation: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setNavTab: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  user: PropTypes.object,
  handleLocationsRender: PropTypes.func,
  isMobileDevice: PropTypes.bool,
  isExternal: PropTypes.bool,
  onSelect: PropTypes.func,
}

export default Recommendation
