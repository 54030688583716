import React from 'react'
import PropTypes from 'prop-types'
import sortBy from 'lodash/sortBy'
import { useStore } from '../../hooks'

import MemberComponent from './MemberComponent'
import InviteeComponent from './InviteeComponent'
import PendingInvite from './PendingInvite'
import Invite from './Invite'

export default function CrewDetails({
  crew,
  user,
  myPID,
  upCrew,
  inviteCodeFn,
  showInviteForm,
  inviteCode,
  setNavTab,
  votingInfo,
  votingInfoByState,
}) {
  const [{ profiles, invites }, dispatch] = useStore()

  const invitesCount = invites && Object.keys(invites).length

  const isMyCrew = crew.captain === user.uid
  if (isMyCrew) {
    const myCount = Object.keys(crew.members).length
    user.membersCount = myCount
  }

  // build lists for crew, invitees, and invites
  let crewList = []
  let inviteeList = []
  let inviteList = []

  const captain = isMyCrew ? user : profiles[crew.captain]

  // first add existing crew members to a crew list and sort
  Object.keys(crew.members).map((memberUID) => {
    const isCaptain = memberUID === crew.captain
    if (isCaptain) return

    const isMe = memberUID === user.uid
    const member = isMe ? user : profiles[memberUID]
    if (member) {
      crewList.push(member)
    }
  })
  // sort crew by fname
  crewList = sortBy(crewList, (o) => o.fname.toLowerCase())

  // only add invitees and invites if it's my crew
  if (isMyCrew) {
    // invitees
    const inveeUIDs = crew.invitees && Object.keys(crew.invitees)
    if (inveeUIDs && inveeUIDs.length > 0) {
      inveeUIDs.map((invUID) => {
        const invitee = profiles[invUID]
        if (invitee) {
          inviteeList.push(invitee)
        }
      })
    }
    if (invitesCount > 0) {
      Object.values(invites).map((invite) => {
        inviteList.push(invite)
      })
    }
  }
  // sort invitees by fname
  inviteeList = sortBy(inviteeList, (o) => o.fname)
  // sort invites by created date
  inviteList = sortBy(inviteList, (o) => o.created.toDate())

  return (
    <>
      <div id="crew-details">
        <div className="details-container">
          <MemberComponent
            member={captain}
            isCaptain={true}
            isMe={isMyCrew}
            setNavTab={setNavTab}
            votingInfo={votingInfo}
            votingInfoByState={votingInfoByState}
          />
        </div>

        <div className="crew-container-mvp">
          {inviteeList.map((member) => {
            return <InviteeComponent key={member.uid} invitee={member} myUID={user.uid} myPID={myPID} />
          })}
          {crewList.map((member) => {
            const isMe = member.uid === user.uid
            return (
              <MemberComponent
                key={member.uid}
                member={member}
                isCaptain={false}
                isMe={isMe}
                isUpcrew={upCrew}
                setNavTab={setNavTab}
                votingInfo={votingInfo}
                votingInfoByState={votingInfoByState}
              />
            )
          })}
          {!upCrew && <Invite inviteCodeFn={inviteCodeFn} showInviteForm={showInviteForm} inviteCode={inviteCode} />}
          {inviteList.map((invite) => {
            return <PendingInvite key={invite.code} invite={invite} inviteCodeFn={inviteCodeFn} showInviteForm={showInviteForm} />
          })}
        </div>
      </div>
    </>
  )
}

CrewDetails.defaultProps = {
  upCrew: false,
  votingInfo: null,
  votingInfoByState: null,
}

CrewDetails.propTypes = {
  crew: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  myPID: PropTypes.string.isRequired,
  upCrew: PropTypes.bool,
  inviteCodeFn: PropTypes.func.isRequired,
  showInviteForm: PropTypes.func.isRequired,
  inviteCode: PropTypes.string.isRequired,
  setNavTab: PropTypes.func.isRequired,
  votingInfo: PropTypes.object,
  votingInfoByState: PropTypes.object,
}
