import { useState, useContext } from 'react'
import firebase from 'firebase/app'
import PropTypes from 'prop-types'
import { mergeUser } from '../reducers/actions'
import stateNames from '../constants/stateNames'
import { useStore } from '.'
import { IS_REGISTERED, IS_NOT_REGISTERED, YES, NO, PENDING } from '../constants/voterInfo'
import logErr from '../lib/err'
import Overlay from '../components/Overlay'
import { ExternalLinkContext } from '../pages/dashboard'

const CheckIcon = '/static/landslide/icons/RegCheckSuccess.png'

export default function useCheckReg({ setNavTab, user, info, onClose }) {
  const [pending, setCheckPending] = useState(false)
  const [error, setError] = useState()
  const [, dispatch] = useStore()
  const [displayOverlay, setDisplayOverlay] = useState(false)
  const [overlayInfo, setOverlayInfo] = useState('')
  const { setExternalLinkModal } = useContext(ExternalLinkContext)

  const checkReg = firebase.functions().httpsCallable('checkReg')

  const fullStateName = stateNames[user.state] || user.state
  const usesInternalRegCheck = info?.l2020_regcheck_method?.value === 'internal'

  // UI calls checkReg
  // returns {status, message, url}
  // UI optimistic updates user  (regStatus = checkReg response) dispatch(mergeUser({regStatus,isMonitoringStatus,voteInfo}))
  // backend saves user.regStatus = {status === (yes, no, pending, warn, fail), message, url, moment}
  // backend saves user.isMonitoringStatus
  // backend saves profile.voteInfo.isRegistered = yes,no,pending

  const handleAnswer = async (status, value, result) => {
    dispatch(
      mergeUser({
        //voteInfo: { [status]: value },
        regStatus: result,
        //isMonitoringStatus: true,
      })
    )
  }

  const handleCheckResults = (result) => {
    console.log('result', result)
    const status = result?.regStatus?.status

    const overlayInfo = {
      text: `Nice! You’re registered to vote in ${fullStateName}!`,
      buttonText: 'Next',
      subtext: 'We will monitor your voter registration status and let you know if anything changes.',
      image: CheckIcon,
    }

    const failedRegCheck = {
      text: `You DON'T seem to be registered to vote in ${fullStateName}!`,
      buttonText: 'Close',
      subtext: 'You should register as soon as possible',
      // image: CheckIcon,
    }
    switch (status) {
      case 'yes':
        handleAnswer(IS_REGISTERED, YES, result?.regStatus)
        setOverlayInfo(overlayInfo)
        setDisplayOverlay(true)
        break
      case 'pending':
        handleAnswer(IS_REGISTERED, PENDING, result?.regStatus)
        if (onClose) onClose()
        setNavTab('more-details')
        break
      case 'no':
        handleAnswer(IS_NOT_REGISTERED, NO, result?.regStatus)
        setOverlayInfo(failedRegCheck)
        setDisplayOverlay(true)
        break
      default:
        handleAnswer(IS_REGISTERED, NO, result?.regStatus)
        if (onClose) onClose()
        setNavTab('more-details')
        break
    }
  }

  const checkRegistration = async () => {
    if (!usesInternalRegCheck) {
      const url = info?.external_tool_verify_status?.value
      if (url) setExternalLinkModal({ url, link: url, message: `Don't forget to return and finish what you were doing!`, modalOpen: true, onClose })
      return
    }

    try {
      setCheckPending(true)
      let result = await checkReg({})
      result = result.data
      setCheckPending(false)
      handleCheckResults(result)
      return result
    } catch (err) {
      logErr(err, 'Registration check failed!')
      setCheckPending(false)
      setError(error)
      throw err
    }
  }

  const RegSuccessOverlay = () => {
    if (displayOverlay)
      return (
        <Overlay
          overlayInfo={overlayInfo}
          closeOverlay={() => {
            setDisplayOverlay(false)
            if (onClose) onClose()
          }}
        />
      )
    else return null
  }

  return [{ RegSuccessOverlay, pending, error }, checkRegistration]
}

useCheckReg.propTypes = {
  setNavTab: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  onClose: PropTypes.func,
}
