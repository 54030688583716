import PropTypes from 'prop-types'
import LoadingSpinner from '../../LoadingSpinner'
import ExternalLink from '../../ExternalLink'
import ReactMarkdown from 'react-markdown'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

const urlRegex = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)

// eslint-disable-next-line react/prop-types
const Section = ({ text, data, v2 }) => {
  // eslint-disable-next-line react/prop-types
  const { value } = data
  let answer = value

  if (isValidDate(answer)) answer = formatDate(answer)

  const isURL = urlRegex.test(value)
  if (!value) {
    return null
  }
  if (value === 'True') {
    answer = 'Yes'
  }
  if (value === 'False') {
    answer = 'No'
  }
  return (
    <div className="info-section">
      <p className="lg">{text}</p>
      {isURL ? <ExternalLink v2={v2} link={'Visit Site'} url={answer} /> : <ReactMarkdown source={answer} />}
    </div>
  )
}

function isValidDate(dateString) {
  if (dateString === null) return false
  var regEx = /^\d{4}-\d{2}-\d{2}$/
  if (!dateString.match(regEx)) return false // Invalid format
  var d = new Date(dateString)
  var dNum = d.getTime()
  if (!dNum && dNum !== 0) return false // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString
}

function formatDate(dateString) {
  dateString = dateString.split('-').join('')
  let date = moment(dateString, 'YYYYMMDD')
  return date.format('MMM Do, YYYY')
}

function DetailsSection({ info, pending, v2 }) {
  if (!info || isEmpty(info)) {
    return LoadingSpinner
  }

  return (
    <div id="details-section">
      {pending && <LoadingSpinner />}
      {/*  AVT removed for now because it might be confusing */}
      {/* {Section(
        "Automatic voter registration?",
        info.registration_automatic_exists
      )} */}
      {info?.registration_deadline_online && (
        <Section v2={v2} text="Voter Registration deadline (online):" data={info.registration_deadline_online} />
      )}
      {info?.vbm_first_day_to_apply && <Section v2={v2} text="Earliest day to apply to vote by mail:" data={info.vbm_first_day_to_apply} />}
      {info?.vbm_deadline_online && <Section v2={v2} text="Deadline to apply online to vote by mail:" data={info.vbm_deadline_online} />}
      {info?.vbm_voted_ballot_deadline_in_person && (
        <Section v2={v2} text="Deadline to return completed ballot in person:" data={info.vbm_voted_ballot_deadline_in_person} />
      )}
      {info?.vbm_voted_ballot_deadline_mail && (
        <Section v2={v2} text="Deadline to return completed ballot by mail:" data={info.vbm_voted_ballot_deadline_mail} />
      )}
      {info?.external_tool_verify_status && <Section v2={v2} text="Verify your registration status:" data={info.external_tool_verify_status} />}
      {info?.external_tool_ovr && <Section v2={v2} text="Register to vote:" data={info.external_tool_ovr} />}
      {info?.official_info_registration && <Section v2={v2} text="Voter registration information:" data={info.official_info_registration} />}
      {info?.official_info_early_voting && <Section v2={v2} text="Early voting information: " data={info.official_info_early_voting} />}
      {info?.external_tool_vbm_application && <Section v2={v2} text="Signup to vote by mail:" data={info.external_tool_vbm_application} />}
      {info?.official_info_vbm && <Section v2={v2} text="Vote by mail information:" data={info.official_info_vbm} />}
      {info?.sdr_election_day && <Section v2={v2} text="Allows same day voter registration:" data={info.sdr_election_day} />}
      {info?.vbm_absentee_ballot_rules && <Section v2={v2} text="Vote by mail rules:" data={info.vbm_absentee_ballot_rules} />}
      {info?.id_requirements_ovr && <Section v2={v2} text="ID requirements for online voter registration:" data={info.id_requirements_ovr} />}
      {info?.id_requirements_vbm && <Section v2={v2} text="ID requirements for voting by mail:" data={info.id_requirements_vbm} />}
      {info['2020_ballot_curing_instructions'] && <Section v2={v2} text="Ballot signature rules:" data={info['2020_ballot_curing_instructions']} />}
    </div>
  )
}

DetailsSection.defaultProps = {
  info: null,
  pending: false,
  v2: false,
}

DetailsSection.propTypes = {
  info: PropTypes.object,
  pending: PropTypes.bool,
  v2: PropTypes.bool,
}

export default DetailsSection
