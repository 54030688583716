import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'react-bootstrap'
const todo = '/static/landslide/icons/ToDo.png'
const check = '/static/landslide/icons/Check.png'
import { VOTE_METHOD, BY_MAIL_OR_ABSENTEE, IN_PERSON, YES, NO } from '../../constants/voterInfo'
import { getShortDate } from '../../lib/dates'

function ballotReturnText(stateInfo) {
  const pr = stateInfo['l2020_ballot_return_deadline_by_mail_date_qual']
  return pr == 'postmarked'
    ? `Send ballot by ${getShortDate(stateInfo['l2020_ballot_return_deadline_by_mail_date'])}`
    : `Ballot must be received by ${getShortDate(stateInfo['l2020_ballot_return_deadline_by_mail_date'])}`
}

function VotingStatus({ member, votingInfo, votingInfoByState }) {
  // Member not populated yet
  if (!member || !votingInfo || !votingInfoByState) {
    return null
  }
  const { state } = member
  const { voteInfo } = member

  // New user, hasn't checked status yet
  if (!voteInfo) {
    return (
      <div className="status-container">
        <p>
          <Image src={todo} className="registration-status-icon" />
          <span>Confirm voter registration</span>
        </p>
      </div>
    )
  }

  const { isRegistered, didVote2020 } = voteInfo
  const byMailOrAbsentee = voteInfo[VOTE_METHOD] === BY_MAIL_OR_ABSENTEE
  const inPerson = voteInfo[VOTE_METHOD] === IN_PERSON

  const restrictedState = votingInfoByState[state].vbm_no_excuse === 'False'
  const exclusiveVBM = votingInfoByState[state].vbm_universal
  const stateIsTrackingBallots = !!votingInfoByState[state].external_tool_absentee_ballot_tracker
  const allowsEarlyVoting = !!votingInfoByState[state]['l2020_early_voting_starts_date']

  const hasVoted2020 = didVote2020 === YES
  const getVotingStatus = () => {
    const temp = []
    if (!voteInfo) {
      temp.push({ text: 'Confirm voter registration', done: false })
      return temp // abort, no more messages to display
    }
    if (voteInfo && votingInfo) {
      // REGISTERED TO VOTE
      switch (isRegistered) {
        case YES:
          temp.push({ text: 'Registered to vote', done: true })
          break
        case NO:
          temp.push({ text: 'Register to vote', done: false })
          return temp // abort, no more messages to display
        case 'pending':
          temp.push({ text: 'Receive registration confirmation', done: false })
          return temp // DONE, no more messages to display
        default:
          temp.push({ text: 'Confirm voter registration', done: false })
          return temp // abort, no more messages to display
      }
      // VOTE METHOD
      switch (voteInfo.voteMethod) {
        case BY_MAIL_OR_ABSENTEE:
          temp.push({ text: 'Voting by mail', done: true })
          break
        case IN_PERSON:
          temp.push({ text: 'Voting in person', done: true })
          break
        default:
          temp.push({ text: 'Confirm voting method', done: false })
          return temp // done
      }
      // VOTE IN PERSON
      if (inPerson) {
        switch (voteInfo.isEarlyVoting) {
          case YES:
            hasVoted2020
              ? temp.push({ text: 'Voted!', done: true })
              : temp.push({
                  text: `Vote Early on ${getShortDate(votingInfoByState[state]['l2020_early_voting_starts_date'])}-${getShortDate(
                    votingInfoByState[state]['l2020_early_voting_ends_date']
                  )}`,
                  done: false,
                })
            break
          case NO:
            hasVoted2020 ? temp.push({ text: 'Voted!', done: true }) : temp.push({ text: 'Vote in person on Nov 3', done: false })
            break
          default:
            hasVoted2020
              ? temp.push({ text: 'Voted!', done: true })
              : // : temp.push({ text: "Select date to vote", done: false });
                temp.push({
                  text: allowsEarlyVoting ? 'Select date to vote' : 'Vote on Nov 3rd',
                  done: false,
                })
            break
        }
        return temp // DONE
      }
      // Non-Exclusive VBM
      if (!exclusiveVBM) {
        if (stateIsTrackingBallots) {
          switch (voteInfo.isTrackingBallot) {
            case YES:
              temp.push({
                text: `Signed up for ballot tracking`,
                done: true,
              })
              break
            default:
              temp.push({ text: 'Sign up for ballot tracking', done: false })
            // return temp; // DONE
          }
        }
        switch (voteInfo.receivedBallot) {
          case YES:
            temp.push({
              text: `Ballot received`,
              done: true,
            })
            break
          default:
            temp.push({ text: 'Receive mail-in ballot', done: false })
            return temp // DONE
        }
        hasVoted2020
          ? temp.push({ text: 'Voted!', done: true })
          : temp.push({
              text: ballotReturnText(votingInfoByState[state]),
              done: false,
            })
        return temp // DONE
      }
      // Restricted State
      if (byMailOrAbsentee && restrictedState) {
        if (stateIsTrackingBallots) {
          switch (voteInfo.isTrackingBallot) {
            case YES:
              temp.push({
                text: `Signed up for ballot tracking`,
                done: true,
              })
              break
            default:
              temp.push({ text: 'Sign up for ballot tracking', done: false })
              return temp // DONE
          }
        }
        switch (voteInfo.receivedBallot) {
          case YES:
            temp.push({
              text: `Ballot received`,
              done: true,
            })
            break
          default:
            temp.push({ text: 'Receive mail-in ballot', done: false })
            return temp // DONE
        }
        hasVoted2020
          ? temp.push({ text: 'Voted!', done: true })
          : temp.push({
              text: ballotReturnText(votingInfoByState[state]),
              done: false,
            })
        return temp // DONE
      }
      // Fallback for exclusive VBM states
      switch (voteInfo.receivedBallot) {
        case YES:
          temp.push({
            text: `Ballot received`,
            done: true,
          })
          break
        default:
          temp.push({ text: 'Receive mail-in ballot', done: false })
          return temp // DONE
      }
      hasVoted2020
        ? temp.push({ text: 'Voted!', done: true })
        : temp.push({
            text: ballotReturnText(votingInfoByState[state]),
            done: false,
          })
      return temp
    } else {
      return temp
    }
  }

  const status = getVotingStatus()

  return (
    <div className="status-container">
      {status.map((s) => {
        return (
          <p key={s.text}>
            <Image src={s.done ? check : todo} className="registration-status-icon" />
            <span className={s.done ? 'green' : ''}>{s.text}</span>
          </p>
        )
      })}
    </div>
  )
}

VotingStatus.defaultProps = {
  votingInfo: null,
  votingInfoByState: null,
}

VotingStatus.propTypes = {
  member: PropTypes.object.isRequired,
  votingInfo: PropTypes.object,
  votingInfoByState: PropTypes.object,
}

export default VotingStatus
