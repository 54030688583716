import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'
import Layout from '../Layout'
import { FiShare } from 'react-icons/fi'
import isEmpty from 'lodash/isEmpty'
import InfluenceByState from './InfluenceByState'
import shareIt from '../../lib/sharing'
import PublicShareBottomDrawer from '../PublicShareBottomDrawer'
import FBConfirm from '../FBConfirm'
import { logSegEvent } from '../../lib/analytics'
import { BUTTON_CLICK } from '../../constants/analytics'
import logErr from '../../lib/err'
import { getItem, setItem } from '../../lib/asyncStorage'

import VotingCheckbox from '../vote-early/VotingCheckbox'

//const INVITE_TEXT = "Hey, check out Landslide. This looks like a real way to win in Nov and fight against voter suppression. It's super easy. They put everything you need to register, vote and make sure your vote gets counted in one place. #jointhelandslide ✊🏿✊🏾✊🏽✊🏼✊🏻";
const INVITE_TEXT =
  "Feeling how essential it is for BIG change on Nov 3rd?! I am :). Friends helping friends vote (+ holding each other accountable) is #1 most effective way to win THIS election (much more than phone banking!) I'm using Landslide to build my voting crew (free, easy App, tracks impact). We often think our friends vote, but remember 2016. We can’t take any chances— In 2020 we need a Landslide! Will you join me on Landslide? #jointhelandslide ✊🏿✊🏾✊🏽✊🏼✊🏻"

const experiment = 'power-share'
const variant = '1'
const tag_score = 'power-share-score'
const tag_button = 'power-share-btn'
const tag_dismiss = 'power-share-dismiss'
const tag_start = 'power-share-start'

function PowerSection({ user }) {
  const [referLink, setReferLink] = useState('')
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  const [showPowerMessage, setShowPowerMessage] = useState(true)
  const [copyLinkModalOpen, setCopyLinkModalOpen] = useState(false)
  const commitScore = user?.scores?.invite || 0
  const power2d = user?.scores?.pow2d || 0
  const noVote2016 = user?.scores?.noVote2016 || 0

  // flag from early voter && willVote2020 -> no user will be able to
  // enter the app without that selection, so it's a reasonable flag
  const earlyVotingRoute = user.v && !user.willVote2020

  useEffect(() => {
    const asyncHelper = async () => {
      const dontShow = await getItem('neverShowPowerMessage')
      setShowPowerMessage(!dontShow)
    }
    const referCode = user?.refer || ''
    const prefix = process.env.I_DOMAIN ? process.env.I_DOMAIN + '.' : ''
    const link = referCode ? `https://${prefix}${process.env.DOMAIN}/r/${referCode}` : ''
    setReferLink(link)
    asyncHelper()
  }, [])

  const shareUniqueLink = async () => {
    logSegEvent(BUTTON_CLICK, { experiment, variant, tag: tag_start })
    try {
      const result = await shareIt({ text: INVITE_TEXT, url: referLink })
      if (result === 'error') {
        setCopyLinkModalOpen(true)
      }
    } catch (e) {
      setCopyLinkModalOpen(true)
    } finally {
      setDrawerIsOpen(false)
    }
  }

  const confirmAction = (e) => {
    e.stopPropagation()
    copy(referLink)
    setCopyLinkModalOpen(false)
  }

  const shareInviteScore = async () => {
    logSegEvent(BUTTON_CLICK, { experiment, variant, tag: tag_score })
    try {
      const result = await shareIt({
        text: `${commitScore} people have already committed to vote because of a cascade that began with a few invites from me. Start your own vote pyramid today. #jointhelandslide`,
        url: referLink,
      })
      if (result === 'error') {
        setCopyLinkModalOpen(false)
      }
    } catch (e) {
      logErr(e, 'Sharing Commit Score failed')
    }
  }

  const dismissShareMessage = async () => {
    await setItem('neverShowPowerMessage', true)
    setShowPowerMessage(false)
    logSegEvent(BUTTON_CLICK, { experiment, variant, tag: tag_dismiss })
    shareUniqueLink()
  }

  const hasInfluence = !isEmpty(user?.scores?.states)

  if (earlyVotingRoute) {
    return <VotingCheckbox />
  }

  return (
    <Layout title="Dashboard - Power" fullScreen>
      <>
        <div id="power-section">
          <div id="power-scores">
            <h2 className="blue">
              {commitScore}
              <FiShare id="gtm-share-invite-score" size={20} onClick={shareInviteScore} />
            </h2>
            <h3>people have committed to vote early because of you</h3>
            <h2 className="green">{power2d}</h2>
            <h3>people you don’t know committed to vote early because of you</h3>
            <h2 className="red">{noVote2016}</h2>
            <h3>people didn't vote in the 2016 election</h3>
            <div
              id="share-link"
              onClick={() => {
                logSegEvent(BUTTON_CLICK, {
                  experiment,
                  variant,
                  tag: tag_button,
                })
                if (showPowerMessage) {
                  setDrawerIsOpen(true)
                } else {
                  shareUniqueLink()
                }
              }}
            >
              <p className="caption">Multiply Your Power: Share Landslide</p>
            </div>
          </div>
          <div id="influence">
            {!hasInfluence ? (
              <h3>Once you build a crew and share your link, we’ll let you know how many state elections you’ve influenced.</h3>
            ) : (
              <InfluenceByState states={user?.scores?.states} />
            )}
          </div>
        </div>
        {drawerIsOpen && (
          <PublicShareBottomDrawer setDrawerIsOpen={setDrawerIsOpen} shareUniqueLink={shareUniqueLink} dismissShareMessage={dismissShareMessage} />
        )}
        {copyLinkModalOpen && (
          <FBConfirm
            url={referLink}
            confirmAction={confirmAction}
            cancelAction={(e) => {
              e.stopPropagation()
              setCopyLinkModalOpen(false)
            }}
          />
        )}
      </>
    </Layout>
  )
}

PowerSection.propTypes = {
  user: PropTypes.object.isRequired,
}

export default PowerSection
