import React from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'react-bootstrap'

function LoadingSpinner({ fullHeight }) {
  // TODO: implement fullheight, have the spinner centered
  return (
    <div className="loading-spinner">
      <div className="d-flex jcc m-5">
        {/* TODO: replace with custom spinner, this one doesn't allow for much customization */}
        <Spinner animation="border" role="status" />
      </div>
    </div>
  )
}

LoadingSpinner.defaultProps = {
  fullHeight: false,
}

LoadingSpinner.propTypes = {
  fullHeight: PropTypes.bool,
}

export default LoadingSpinner
