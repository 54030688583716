import React from 'react'
import PropTypes from 'prop-types'
import StateDetails from '../dashboard/VoterInfo/StateDetails'
import { BUTTON_CLICK } from '../../constants/analytics'
import { logSegEvent } from '../../lib/analytics'
const ArrowWhite = '/static/landslide/icons/arrow-white.png'

const experiment = 'check-state-info'
const variant = 1

function StateInformation({ info, setView, state }) {
  return (
    <div id="state-information">
      <header>
        <div>
          <div className="top-bar">
            <button className="back-button" onClick={() => setView('preference')}>
              <img id="gtm-page-header-go-back" src="/static/landslide/icons/arrow.png" alt="go back" />
            </button>
            <h1>{state.state}</h1>

            <h3></h3>
          </div>
        </div>
      </header>
      <StateDetails info={info} pending={false} v2 />
    </div>
  )
}

StateInformation.propTypes = {
  info: PropTypes.object.isRequired,
  setView: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
}

export default StateInformation
