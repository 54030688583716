import React from 'react'
import PropTypes from 'prop-types'
import scrollToTop from '../../lib/scrollToTop'
import stateNames from '../../constants/stateNames'
const ArrowWhite = '/static/landslide/icons/arrow-white.png'

function DashboardHeader({ navTab, setNavTab, state }) {
  const fullStateName = stateNames[state] || state
  if (navTab === 'user-form' || navTab === 'state-details' || navTab === 'my-options') {
    return (
      <div id="alt-header">
        <button
          className={navTab === 'state-details' ? 'state-title' : ''}
          onClick={() => {
            scrollToTop()
            setNavTab(navTab === 'user-form' ? 'voter-info-profile' : 'voter-info-early-voting')
          }}
        >
          <img src={ArrowWhite} alt="go back" />
        </button>
        {(navTab === 'state-details' || navTab === 'my-options') && <h1 className="state-title">{fullStateName}</h1>}
        {navTab === 'user-form' && <h1>Landslide</h1>}
      </div>
    )
  } else if (navTab === 'check-registration' || navTab === 'more-details' || navTab === 'more') {
    return <></>
  } else if (navTab === 'locations') {
    return (
      <div id="alt-header">
        <button
          className={navTab === 'state-details' ? 'state-title' : ''}
          onClick={() => {
            scrollToTop()
            setNavTab('voter-info-early-voting')
          }}
        >
          <img src={ArrowWhite} alt="go back" />
        </button>
        <h1 className="state-title">Locations</h1>
      </div>
    )
  } else {
    return (
      <div id="dashboard-header">
        <h1>Landslide</h1>
      </div>
    )
  }
}

DashboardHeader.defaultProps = {
  state: '',
}
DashboardHeader.propTypes = {
  navTab: PropTypes.string.isRequired,
  setNavTab: PropTypes.func.isRequired,
  state: PropTypes.string,
}
export default DashboardHeader
