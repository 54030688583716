import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { ExternalLinkContext } from '../pages/dashboard'
import { ExternalLinkContextV2 } from './IndexPage'

function ExternalLink({ link, url, message, button, v2 }) {
  const { setExternalLinkModal } = useContext(v2 ? ExternalLinkContextV2 : ExternalLinkContext)
  if (button) {
    return (
      <div className="external-link" onClick={() => setExternalLinkModal({ link, url, message, modalOpen: true })}>
        {link}
      </div>
    )
  } else {
    return (
      <a className="external-link" onClick={() => setExternalLinkModal({ link, url, message, modalOpen: true })}>
        {link}
      </a>
    )
  }
}

ExternalLink.defaultProps = {
  message: `Don't forget to return and finish what you were doing!`,
  v2: false,
}

ExternalLink.propTypes = {
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  url: PropTypes.string.isRequired,
  message: PropTypes.string,
  v2: PropTypes.bool, // early voting campaign has a different context as it's not hosted under /dashboard, v2 loads that different context
}

export default ExternalLink
