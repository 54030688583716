import { Form } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { IS_REGISTERED, IS_CHECKING, IS_REGISTERING, IS_MONITORING_STATUS, YES, NO, PENDING } from '../../../constants/voterInfo'

import RegisteredForm from './RegisteredForm'
import stateNames from '../../../constants/stateNames'
import NotRegisteredForm from './NotRegisteredForm'
import PendingRegistrationForm from './PendingRegistrationForm'
import ExternalLink from '../../ExternalLink'

const todo = '/static/landslide/icons/ToDo.png'
const ProfileIcon = '/static/landslide/icons/ProfileIconActive.png'

function VoterInfoPage({
  user,
  requestInProcess,
  setNavTab,
  info,
  handleAnswer,
  alloySelfDeclaredStatusDiverge,
  registerToVoteButtonClick,
  externalLinkButtonRender,
  setDisplayOverlay,
  shareUniqueLink,
  powerReferCode,
  scrollToSection,
  handleButtonClick,
}) {
  const failedStatus = user.regStatus && user.regStatus.status === 'fail'
  const fullStateName = stateNames[user.state] || user.state
  const voteInfo = user.voteInfo || {}
  const isRegistered = voteInfo[IS_REGISTERED] === YES
  const isNotRegistered = voteInfo[IS_REGISTERED] === NO
  const isPending = voteInfo[IS_REGISTERED] === PENDING
  const isMonitoringStatus = user[IS_MONITORING_STATUS] === true
  const usesInternalRegCheck = info?.l2020_regcheck_method?.value === 'internal'

  return (
    <>
      <div className="profile-details-container">
        {user.photoURL ? <img src={user.photoURL} alt="user photo" /> : <img src={ProfileIcon} alt="user photo" />}
        <div className={isMonitoringStatus ? 'name-location-container' : 'name-location-container not-monitoring'}>
          <h2>{`${user.fname} ${user.lname}`}</h2>
          <span>{`${user.city}, ${fullStateName}`}</span>
          {isMonitoringStatus ? (
            <span className="monitoring">
              <div className="blue-circle" /> Monitoring registration status
            </span>
          ) : (
            <span />
          )}
        </div>
        <img className="todo" src={todo} onClick={() => setNavTab('user-form')} />
      </div>

      <div id="content">
        <h3>Are you registered to vote?</h3>
        <Form>
          <Form.Check
            custom
            type="checkbox"
            id="registered"
            label={'Yes'}
            checked={isRegistered}
            disabled={requestInProcess}
            onChange={() => handleAnswer(IS_REGISTERED, isRegistered ? null : YES, true)}
          />
          <Form.Check
            custom
            type="checkbox"
            id="not-registered"
            label={'No'}
            checked={isNotRegistered}
            disabled={requestInProcess}
            onChange={() => handleAnswer(IS_REGISTERED, isNotRegistered ? null : NO, true)}
          />
          <Form.Check
            custom
            type="checkbox"
            id="is-waiting-registered"
            label={"I applied, but I'm still waiting"}
            checked={isPending}
            disabled={requestInProcess}
            onChange={() => handleAnswer(IS_REGISTERED, isPending ? null : PENDING, true)}
          />
        </Form>

        {/* Alloy and self declared voter status diverge, 
        can be checked by alloy, but if the user has a fail status,
        we have another component that asks, so we won't register
        */}
        {alloySelfDeclaredStatusDiverge() && usesInternalRegCheck && !failedStatus && (
          <>
            <button onClick={() => setNavTab('check-registration')} className="btn btn-primary btn-block">
              Check again
            </button>
            <p>Looks like you changed your registration status manually. Please check your status again.</p>
          </>
        )}

        {!isRegistered && !isNotRegistered && !isPending && (
          <>
            {usesInternalRegCheck ? (
              <>
                <button onClick={() => handleButtonClick(IS_REGISTERED, IS_CHECKING, true)} className="btn btn-primary btn-block">
                  Check Registration Status
                </button>
                <p>
                  To prevent voter suppression, we recommend everyone check their voter status. We will periodically check your status and inform you
                  of any changes before the election.
                </p>
              </>
            ) : (
              <>
                <ExternalLink
                  link={externalLinkButtonRender('Check Registration Status')}
                  url={info.external_tool_verify_status.value}
                  button={true}
                />
                <p>To prevent voter suppression, we recommend everyone check their voter status.</p>
              </>
            )}
          </>
        )}

        {isRegistered && (
          <RegisteredForm
            user={user}
            info={info}
            handleAnswer={handleAnswer}
            setDisplayOverlay={setDisplayOverlay}
            profileVoteInfo={voteInfo}
            handleButtonClick={handleButtonClick}
            usesInternalRegCheck={usesInternalRegCheck}
            scrollToSection={scrollToSection}
            requestInProcess={requestInProcess}
          />
        )}

        {isNotRegistered && (
          <NotRegisteredForm
            user={user}
            info={info}
            handleAnswer={handleAnswer}
            registerToVoteButtonClick={registerToVoteButtonClick}
            setNavTab={setNavTab}
            isMonitoringStatus={isMonitoringStatus}
            externalLinkButtonRender={externalLinkButtonRender}
          />
        )}

        {isPending && (
          <PendingRegistrationForm
            user={user}
            usesInternalRegCheck={usesInternalRegCheck}
            handleButtonClick={handleButtonClick}
            info={info}
            fullStateName={fullStateName}
            isMonitoringStatus={isMonitoringStatus}
            externalLinkButtonRender={externalLinkButtonRender}
            setNavTab={setNavTab}
          />
        )}

        <div id="election-day-is-coming">
          <h3>Election Day is coming.</h3>
          <h4>Share Landslide on social media to help your friends vote early or in person.</h4>
          <button className="btn btn-primary" onClick={() => shareUniqueLink()}>
            Spread the word
          </button>
        </div>
      </div>
    </>
  )
}

VoterInfoPage.propTypes = {
  user: PropTypes.object.isRequired,
  setNavTab: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  scrollToSection: PropTypes.string,
  info: PropTypes.object,
  pending: PropTypes.bool,
}

export default VoterInfoPage
