import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useStore, useCloudFn2 } from '../../../hooks'
import PropTypes from 'prop-types'
import firebase from 'firebase/app'
import copy from 'copy-to-clipboard'
import { updateUIState } from '../../../reducers/actions'

import LoadingSpinner from '../../../components/LoadingSpinner'
import { VOTE_2020_CAMPAIGN } from '../../../constants/posse'
import { IS_REGISTERED, IS_CHECKING, IS_REGISTERING, IS_MONITORING_STATUS, YES, NO, PENDING } from '../../../constants/voterInfo'

import StateDetails from './StateDetails'
import VoterInfoPage from './VoterInfoPage'
import VoterInfoTabs from './VoterInfoTabs'
import EarlyVoting from './EarlyVoting'
import FBConfirm from '../../FBConfirm'
import OverlayRegStatusNotifyV2 from './OverlayRegStatusNotifyV2' // overlay for new users just coming from v2 join
import Overlay from '../../Overlay'
import { mergeUser } from '../../../reducers/actions'
import shareIt from '../../../lib/sharing'
import scrollToTop from '../../../lib/scrollToTop'
import { CREW_TEXT } from '../../../constants/messages'

function VoterInfo({ navTab, user, setNavTab, info, pending, civicPending, powerReferCode, handleLocationsRender }) {
  const [displayOverlay, setDisplayOverlay] = useState(false)
  const [referLink, setReferLink] = useState('')
  const [copyLinkModalOpen, setCopyLinkModalOpen] = useState(false)
  const [requestInProcess, setRequestInProcess] = useState(false)
  const router = useRouter()
  const [{}, dispatch] = useStore()
  const [{ pending: earlyVotingCivicInfo }, getCivicInfo] = useCloudFn2('getCivicInfo')

  const updateProfile = firebase.functions().httpsCallable('updateProfile')

  const voteInfo = user.voteInfo || {}

  const sectionSplit = router.asPath.split('section=')
  const scrollToSection = sectionSplit.length > 1 ? sectionSplit.pop() : '' // i.e. 'confirm' , 'register' etc

  useEffect(() => {
    if (!user.civicInfo) {
      getCivicInfo({})
    }
  }, [user])

  useEffect(() => {
    const referCode = powerReferCode || ''
    const prefix = process.env.I_DOMAIN ? process.env.I_DOMAIN + '.' : ''
    const link = referCode ? `https://${prefix}${process.env.DOMAIN}/r/${referCode}` : ''
    setReferLink(link)
  }, [])

  useEffect(() => {
    scrollToTop()
  }, [navTab])

  const setVoteInfo = (voteInfo) => {
    dispatch(mergeUser({ voteInfo }))
  }

  const updateVoteInfo = async (voteInfo) => {
    setRequestInProcess(true)
    await updateProfile({
      campaign: VOTE_2020_CAMPAIGN,
      profile: {
        voteInfo,
      },
    })
    setRequestInProcess(false)
  }

  const externalLinkButtonRender = (text) => {
    return <button className="btn btn-primary">{text}</button>
  }

  const handleAnswer = (statusVar, value, isRegisteredForm) => {
    // clear when click on 'are you registered to vote' selection
    const timestamp = new Date().valueOf()
    if (isRegisteredForm) {
      const resetVoteInfo = {
        [statusVar]: value,
        updated: timestamp,
      }
      setVoteInfo(resetVoteInfo)
      updateVoteInfo(resetVoteInfo)
    } else {
      const newVoterInfo = {
        ...voteInfo,
        [statusVar]: value,
        updated: timestamp,
      }
      setVoteInfo(newVoterInfo)
      updateVoteInfo(newVoterInfo)
    }
  }

  const handleButtonClick = async (statusVar, updateVar, isInternal) => {
    if (updateVar) {
      try {
        // did it with async/await
        // so the cloudfn update works and also redirects
        await handleAnswer(updateVar, true)
      } catch (error) {
        console.log(error)
      }
    }
    // if it's an internal thing, then open the check reg form
    if (isInternal) {
      dispatch(updateUIState({ regCheckType: statusVar }))
      setNavTab('check-registration')
    }
  }

  const registerToVoteButtonClick = () => {
    handleAnswer(IS_REGISTERING, true)
  }

  const shareUniqueLink = async () => {
    try {
      const result = await shareIt({
        text: CREW_TEXT,
        url: referLink,
      })
      if (result === 'error') {
        setCopyLinkModalOpen(true)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const confirmAction = (e) => {
    e.stopPropagation()
    copy(referLink)
    setCopyLinkModalOpen(false)
  }

  /**
   *  determines if CTA is shown to recheck status
   *
   * @returns {boolean} if checked status and self declared status diverge return true
   */
  const alloySelfDeclaredStatusDiverge = () => {
    const { regStatus } = user || null
    if (!voteInfo?.isRegistered || !regStatus) {
      // no regStatus or no user selected status
      return false
    }
    if (voteInfo.isRegistered !== regStatus.status) {
      return true
    }
    return false
  }

  if (pending) {
    return (
      <div id="voter-info-pending">
        <LoadingSpinner />
      </div>
    )
  }

  const overlayInfo = {
    text: '🎉 You voted! Now, make sure your friends vote too.',
    buttonText: 'Check On Your Crew',
  }

  return (
    <>
      {info && (
        <div id="voter-info-page">
          <VoterInfoTabs setNavTab={setNavTab} navTab={navTab} />
          {(navTab === 'voter-info' || navTab === 'voter-info-early-voting') && !earlyVotingCivicInfo && (
            <EarlyVoting user={user} info={info} setNavTab={setNavTab} handleLocationsRender={handleLocationsRender} civicPending={civicPending} />
          )}
          {navTab === 'voter-info-state-guide' && <StateDetails info={info} pending={pending} />}{' '}
          {navTab.match(/voter-info-profile/i) && (
            <VoterInfoPage
              user={user}
              info={info}
              requestInProcess={requestInProcess}
              powerReferCode={powerReferCode}
              displayOverlay={displayOverlay}
              setNavTab={setNavTab}
              handleAnswer={handleAnswer}
              alloySelfDeclaredStatusDiverge={alloySelfDeclaredStatusDiverge}
              registerToVoteButtonClick={registerToVoteButtonClick}
              handleButtonClick={handleButtonClick}
              externalLinkButtonRender={externalLinkButtonRender}
              setDisplayOverlay={setDisplayOverlay}
              shareUniqueLink={shareUniqueLink}
              scrollToSection={scrollToSection}
            />
          )}
          {displayOverlay && <Overlay overlayInfo={overlayInfo} closeOverlay={() => setNavTab('crew')} />}
          {navTab === 'voter-info-status' && <OverlayRegStatusNotifyV2 setNavTab={setNavTab} user={user} />}
          {copyLinkModalOpen && (
            <FBConfirm
              url={referLink}
              confirmAction={confirmAction}
              cancelAction={(e) => {
                e.stopPropagation()
                setCopyLinkModalOpen(false)
              }}
            />
          )}
        </div>
      )}
    </>
  )
}

VoterInfo.defaultProps = {
  info: null,
  pending: false,
}

VoterInfo.propTypes = {
  user: PropTypes.object.isRequired,
  setNavTab: PropTypes.func.isRequired,
  info: PropTypes.object,
  pending: PropTypes.bool,
  navTab: PropTypes.string.isRequired,
}

export default VoterInfo
