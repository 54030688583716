import React from 'react'
import PropTypes from 'prop-types'

function MyOptions({ goBack, state, info, isInapp }) {
  const textWithout = info?.vbm_ip_without_ballot_text
  const textWith = info?.vbm_ip_with_ballot_text
  const fullStateName = state?.state
  return (
    <div id="my-options">
      <header>
        <div>
          {isInapp ? (
            <div id="inapp-header"></div>
          ) : (
            <div id="top-bar">
              <button className="back-button" onClick={goBack}>
                <img id="gtm-page-header-go-back" src="/static/landslide/icons/arrow.png" alt="go back" />
              </button>
              <h1>{fullStateName}</h1>

              {/* <span className="header-right"> </span> */}
            </div>
          )}
          <div className="narrow">
            <p>What should I do if I want to vote in person but:</p>
            <ul>
              <li>I’m registered to vote by mail / absentee</li>
              <li>I will receive a mail in ballot</li>
              <li>I’ve already received my ballot</li>
            </ul>
          </div>
        </div>
      </header>
      <main>
        <h3>If you haven’t received your ballot yet, or lost it:</h3>
        <p>{textWithout}</p>
        <h3>If you have your ballot:</h3>
        <p>{textWith}</p>
      </main>
    </div>
  )
}

MyOptions.propTypes = {
  goBack: PropTypes.func.isRequired,
  state: PropTypes.object,
  info: PropTypes.object,
  isInapp: PropTypes.bool,
}

export default MyOptions
