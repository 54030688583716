import React, { useEffect, useState } from 'react'
import { useStore } from '../hooks'
import Layout from '../components/Layout'
import EnterZip from '../components/vote-early/EnterZip'
import SetPreference from '../components/vote-early/SetPreference'
import StateInformation from '../components/vote-early/StateInformation'
import JoinFormV2 from '../components/vote-early/JoinFormV2'
import ExternalLinkModal from '../components/ExternalLinkModal'
import MyOptions from '../components/vote-early/MyOptions'
import scrollToTop from '../lib/scrollToTop'
import { useRouter } from 'next/router'
import { isMobileDevice } from '../lib/sharing'
import stateNames from '../constants/stateNames'
import LoadStateInfo from '../components/vote-early/LoadStateInfo'
import PropTypes from 'prop-types'

export const ExternalLinkContextV2 = React.createContext()

const INITTIAL_EXTERNAL_LINK_CONTEXT = {
  modalOpen: false,
  link: '',
  url: '',
  message: '',
}

function IndexPage({ nonPartisan = false }) {
  // check for a state query arg
  const router = useRouter()
  const stateAbbr = router.query.state
  const stateName = stateNames[stateAbbr]
  // if we have one, then set the view to load the state info
  console.log('RENDER IndexPage', 'stateAbbr', stateAbbr)
  const [view, setView] = useState(stateName ? 'load-info' : 'enter-zip')

  const [state, setState] = useState()
  const [info, setInfo] = useState()
  const [zip, setZip] = useState('')
  const [preference, setPreference] = useState()
  const [externalLinkModal, setExternalLinkModal] = useState(INITTIAL_EXTERNAL_LINK_CONTEXT)
  const [{ auth }] = useStore()

  useEffect(() => {
    scrollToTop()
  }, [view])

  // if we have a state query param, use it instead of zip lookup
  useEffect(() => {
    if (stateAbbr && stateName) {
      setState({ abbr: stateAbbr, state: stateName })
      setView('load-info')
    } else if (stateAbbr) {
      // if it's an invalid state, clear the query param
      router.replace('/')
    }
  }, [stateAbbr])

  if (auth.isAuthed && isMobileDevice) {
    // prevent flash
    return null
  }

  return (
    <div id={nonPartisan ? 'vote-page' : 'vote-early'}>
      <ExternalLinkContextV2.Provider value={{ ...externalLinkModal, setExternalLinkModal }}>
        <Layout fullScreen>
          {view === 'enter-zip' && (
            <EnterZip nonPartisan={nonPartisan} setState={setState} setInfo={setInfo} setView={setView} zip={zip} setZip={setZip} />
          )}
          {view === 'load-info' && <LoadStateInfo stateAbbr={stateAbbr} setView={setView} setInfo={setInfo} />}
          {view === 'preference' && <SetPreference state={state} info={info} setView={setView} setPreference={setPreference} zip={zip} />}
          {view === 'info' && <StateInformation info={info} setView={setView} state={state} />}
          {view === 'form' && <JoinFormV2 zip={zip} state={state} preference={preference} setView={setView} />}
          {view === 'my-options' && <MyOptions state={state} info={info} goBack={() => setView('preference')} />}
        </Layout>
        {externalLinkModal.modalOpen && <ExternalLinkModal {...externalLinkModal} setExternalLinkModal={setExternalLinkModal} />}
      </ExternalLinkContextV2.Provider>
    </div>
  )
}

IndexPage.propTypes = {
  nonPartisan: PropTypes.bool,
}

export default IndexPage
