const recommendationsInfo = {
  vbmDrop: {
    title: 'Vote early by drop box',
    doThisIf: [
      'Your ballot is guaranteed to arrive before Election Night',
      'You avoid polling locations and crowds',
      'You can track your ballot if your state allows it',
    ],
    locationsTitle: 'Drop box locations and hours for your area:',
    ballotRulesTitle: 'Ballot signature rules:',
    idTitle: 'ID requirements for voting by mail:',
    checkMobile: 'Check your registration status and get drop box locations and hours for your county.',
    checkDesktop: 'To check your registration status and get drop box locations and hours for your county, go to',
  },
  vbmDropEP: {
    title: 'Vote early by drop box',
    doThisIf: [
      'Your vote will likely be counted on election night',
      'You avoid polling locations and crowds',
      'You will have time to fix issues with your voter registration ',
      'You can track your ballot if your state allows it',
    ],
    locationsTitle: 'Drop box locations and hours for your area:',
    ballotRulesTitle: 'Ballot signature rules:',
    idTitle: 'ID requirements for voting by mail:',
    checkMobile: 'Check your registration status and get drop box locations and hours for your county.',
    checkDesktop: 'To check your registration status and get drop box locations and hours for your county, go to',
  },
  vbmMail: {
    title: 'Vote early by mail',
    doThisIf: ['It minimizes human contact', "It's the easiest way to vote", 'You can track your ballot if your state allows it'],
    ballotRulesTitle: 'Ballot signature rules:',
    idTitle: 'ID requirements for voting by mail:',
    checkMobile: 'Check your registration status and sign up for ballot tracking.',
    checkDesktop: 'To check your registration status and get drop box locations and hours for your county, go to',
  },
  vbmMailEP: {
    title: 'Vote early by mail',
    doThisIf: [
      'It minimizes human contact',
      "It's the easiest way to vote",
      'You will have time to fix issues with voter registration',
      'You can track your ballot if your state allows it',
    ],
    ballotRulesTitle: 'Ballot signature rules:',
    idTitle: 'ID requirements for voting by mail:',
    checkMobile: 'Check your registration status and sign up for ballot tracking.',
    checkDesktop: 'To check your registration status and get drop box locations and hours for your county, go to',
  },
  vipED: {
    title: 'Vote in person on Nov 3',
    doThisIf: [
      'Your vote will be counted on election night',
      'Poll workers are available to help',
      'In person voting has the lowest ballot rejection rate of all voting methods',
    ],
    locationsTitle: 'Voting locations and times for you:',
    idTitle: 'What to bring with you',
    checkMobile: 'Check your registration status and get voting locations and hours for your county.',
    checkDesktop: 'To check your registration status and get voting locations and hours for your county, go to',
  },
  vipEarly: {
    title: 'Vote early in person',
    doThisIf: [
      'Your vote will be counted on Election Night',
      'The polls will be less crowded',
      'You will have time to fix issues that may arise at the polls or with your voter registration',
    ],
    locationsTitle: 'Early voting locations and times for you:',
    idTitle: 'What to bring with you',
    checkMobile: 'Check your registration status and get voting locations, dates, and hours for your county.',
    checkDesktop: 'To check your registration status and get voting locations, dates, and hours for your county, go to ',
  },
}

export default recommendationsInfo
