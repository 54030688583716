import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import logErr from '../../lib/err'

export default function InvitationTrackForm({ inviteData, showInviteForm, inviteCodeFn }) {
  const { code, name } = inviteData
  const [isFocused, setIsFocused] = useState(false)
  const [isSaving, setIsSaving] = useState()
  const [inviteName, setInviteName] = useState(name || '')
  const inputRef = useRef()

  useEffect(() => {
    function handleFocus() {
      setIsFocused(true)
      inputRef.current.scrollIntoView(true)
    }
    inputRef.current.addEventListener('focus', () => handleFocus())
    return inputRef.current.removeEventListener('focus', () => handleFocus())
  }, [])

  const saveName = async (e) => {
    setIsSaving(true)
    e.preventDefault()
    e.stopPropagation()
    await inviteCodeFn({ code, name: inviteName }).catch((err) => {
      logErr(err, 'failed to update invite name', JSON.stringify(inviteData))
    })
    setIsSaving(false)
    showInviteForm(false)
  }

  return (
    <div id="track-friend-form-container">
      <div id="track-friend-form">
        {}
        <div className="content">
          {!isFocused && (
            <>
              <h4>If you would like to keep track of the person you invited, enter their name below.</h4>
              <p>Landslide does not collect any information about your contacts prior to them joining.</p>
            </>
          )}
          <div id="form">
            <Form autoComplete="on" onSubmit={saveName}>
              <Form.Group className="custom-input">
                <Form.Label htmlFor="fname" className="custom-label">
                  Your friend&apos;s name
                </Form.Label>
                <Form.Control
                  name="fname"
                  placeholder={'First Name'}
                  onChange={(e) => {
                    setInviteName(e.target.value)
                  }}
                  type="text"
                  value={inviteName}
                  ref={inputRef}
                />
              </Form.Group>
            </Form>
          </div>
          <div className="btn-wrapper">
            <button id="gtm-close-welcome-message" className="btn btn-primary btn-block" type="submit" onClick={saveName}>
              {isSaving ? 'Saving' : 'Keep Track'}
            </button>
            <button
              id="gtm-dismiss-welcome-message"
              onClick={() => showInviteForm(false)}
              className="btn btn-primary btn-block transparent"
              type="button"
            >
              Skip
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

InvitationTrackForm.propTypes = {
  inviteData: PropTypes.object,
  showInviteForm: PropTypes.func.isRequired,
  inviteCodeFn: PropTypes.func.isRequired,
}
