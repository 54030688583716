import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
const RemovePendingUser = '/static/landslide/icons/RemovePendingUser.png'

export default function PendingInvite({ invite, inviteCodeFn, showInviteForm }) {
  const [hidden, setHidden] = useState(false)
  const { code, name } = invite

  if (hidden) {
    return null
  }

  return (
    <div id="pending-invites">
      <div className="member-container">
        <div
          className="profile-pic-container ml-n1"
          onClick={() => {
            setHidden(true)
            inviteCodeFn({ code, show: false })
          }}
        >
          <img src={RemovePendingUser} alt="Profile Picture" className="profile-pic" />
        </div>
        <div className="s-container">
          <div className="member-row">
            <div className="name-row">
              <h3>
                Pending Invite: <span onClick={() => showInviteForm(true, { code, name })}>{name ? name : 'Add name'}</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

PendingInvite.propTypes = {
  invite: PropTypes.object.isRequired,
  inviteCodeFn: PropTypes.func.isRequired,
  showInviteForm: PropTypes.func.isRequired,
}
