import React, { useState } from 'react'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'
import differenceInDays from 'date-fns/differenceInDays'

import ActionCard from './ActionCard'
import FBConfirm from '../FBConfirm'
import shareIt from '../../lib/sharing'
import { logSegEvent } from '../../lib/analytics'
import { BUTTON_CLICK } from '../../constants/analytics'

import { CREW_TEXT, POWER_TEXT } from '../../constants/messages'

// EXPERIMENTS - Setup as Per Card experiments, with no tags, increment at the variant level when making changes
const exp_invite = 'action-cards-invite'
const var_invite = '1'
const exp_status = 'action-cards-status'
const var_status = '1'
const exp_profile = 'action-cards-profile'
const var_profile = '1'
const exp_fbconfirm = 'action-cards-fbconfirm'
const var_fbconfirm = '1'

export default function ActionCardsBar({ inviteCodeFn, showInviteForm, inviteCode, user, setNavTab, setPublicShareDrawerIsOpen, showPowerMessage }) {
  const [copyLinkModalOpen, setCopyLinkModalOpen] = useState(false)
  const getCrewLink = (inviteCode) => {
    const prefix = process.env.I_DOMAIN ? process.env.I_DOMAIN + '.' : ''
    const inviteLink = inviteCode ? `https://${prefix}${process.env.DOMAIN}/c/` + inviteCode : ''
    return inviteLink
  }

  const shareUniqueLink = async (EXP, TYPE) => {
    const inviteLink = getCrewLink(inviteCode)
    logSegEvent(BUTTON_CLICK, { experiment: EXP, variant: TYPE })

    try {
      if (inviteCodeFn) {
        const result = await shareIt({ text: CREW_TEXT, url: inviteLink })
        if (result === 'shared') {
          const invite = await inviteCodeFn({})
          inviteCode = invite.data.code
          showInviteForm(true, { code: inviteCode, name: '' })
        }
        if (result === 'error') {
          setCopyLinkModalOpen(true)
        }
      }
    } catch (e) {
      // fallback for fb/twitter browser
      setCopyLinkModalOpen(true)
    }
  }

  const sharePower = async (EXP, TYPE) => {
    logSegEvent(BUTTON_CLICK, { experiment: EXP, variant: TYPE })
    const referCode = user.refer || ''
    const prefix = process.env.I_DOMAIN ? process.env.I_DOMAIN + '.' : ''
    const link = referCode ? `https://${prefix}${process.env.DOMAIN}/r/${referCode}` : ''

    try {
      await shareIt({ text: POWER_TEXT, url: link })
    } catch (e) {
      console.log(e)
    }
  }

  const confirmAction = async (e) => {
    e.stopPropagation()
    const inviteLink = getCrewLink(inviteCode)
    copy(inviteLink)
    try {
      const invite = await inviteCodeFn({})
      inviteCode = invite.data.code
      setCopyLinkModalOpen(false)
      logSegEvent(BUTTON_CLICK, {
        experiment: exp_fbconfirm,
        variant: var_fbconfirm,
      })
      showInviteForm(true, { code: inviteCode, name: '' })
    } catch (e) {
      //
    }
  }

  const daysToElection = () => {
    return differenceInDays(new Date('2020-11-03'), new Date()) + 1
  }

  const isRegistered = user?.voteInfo?.isRegistered === 'yes'

  return (
    <div id="action-cards-bar">
      <ActionCard>
        <p>{daysToElection()} days until Election Day ✊🏿✊🏾✊🏽✊🏼✊🏻 Invite 5 friends to vote early and in person.</p>

        <div>
          <button id="gtm-invite-friend-card" onClick={() => shareUniqueLink('crew', exp_invite, var_invite)}>
            Invite Friends
          </button>
        </div>
      </ActionCard>
      <ActionCard>
        <p>Share Landslide on social and help your friends vote early 🇺🇸</p>
        <div>
          <button
            id="gtm-voting-info-by_state-card"
            onClick={() => {
              if (showPowerMessage) {
                setPublicShareDrawerIsOpen(true)
              } else {
                sharePower()
              }
            }}
          >
            Spread the Word
          </button>
        </div>
      </ActionCard>
      <ActionCard>
        <p>Landslide is a small non-profit. Can you pitch in $3 to help us fight the good fight?</p>
        <div>
          <a href="https://secure.actblue.com/donate/jointhelandslide" target="_blank" rel="noreferrer">
            <button
              id="gtm-voting-info-by_state-card"
              className="donate-button"
              onClick={() =>
                logSegEvent(BUTTON_CLICK, {
                  experiment: exp_profile,
                  variant: var_profile,
                })
              }
            >
              Donate Now
            </button>
          </a>
        </div>
      </ActionCard>
      {!isRegistered && (
        <ActionCard>
          <p>We recommend that everyone confirm their voter registration status ✅</p>
          <div>
            <button
              id="gtm-check-vote-status-card"
              onClick={() => {
                logSegEvent(BUTTON_CLICK, {
                  experiment: exp_status,
                  variant: var_status,
                })
                setNavTab('voter-info-profile')
              }}
            >
              Check Status
            </button>
          </div>
        </ActionCard>
      )}
      {/* invisible spaceholder */}

      <p>X</p>
      {copyLinkModalOpen && (
        <FBConfirm
          crew
          url={getCrewLink(inviteCode)}
          confirmAction={confirmAction}
          cancelAction={(e) => {
            e.stopPropagation()
            setCopyLinkModalOpen(false)
          }}
        />
      )}
    </div>
  )
}

ActionCardsBar.propTypes = {
  user: PropTypes.object.isRequired,
  setNavTab: PropTypes.func.isRequired,
  inviteCodeFn: PropTypes.func.isRequired,
  showInviteForm: PropTypes.func.isRequired,
  inviteCode: PropTypes.string.isRequired,
  setPublicShareDrawerIsOpen: PropTypes.func.isRequired,
  showPowerMessage: PropTypes.bool,
}
