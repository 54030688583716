import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import ExternalLink from '../../ExternalLink'
import scrollToTop from '../../../lib/scrollToTop'
import { getShortDate } from '../../../lib/dates'
import stateNames from '../../../constants/stateNames'

import {
  VOTE_METHOD,
  BALLOT_TRACKING,
  RECIEVED_BALLOT,
  IS_EARLY_VOTING,
  IS_MONITORING_STATUS,
  BY_MAIL_OR_ABSENTEE,
  AUTO_MONITOR_STATUS,
  IN_PERSON,
  NOT_SURE,
  DID_VOTE_2020,
  YES,
  NO,
} from '../../../constants/voterInfo'

function RegisteredForm({
  user,
  info,
  handleAnswer,
  handleButtonClick,
  setDisplayOverlay,
  usesInternalRegCheck,
  profileVoteInfo,
  scrollToSection,
  requestInProcess,
}) {
  const byMailOrAbsentee = profileVoteInfo[VOTE_METHOD] === BY_MAIL_OR_ABSENTEE
  const inPerson = profileVoteInfo[VOTE_METHOD] === IN_PERSON
  const notSure = profileVoteInfo[VOTE_METHOD] === NOT_SURE
  const isTrackingBallot = profileVoteInfo[BALLOT_TRACKING] === YES
  const isNotTrackingBallot = profileVoteInfo[BALLOT_TRACKING] === NO
  const receivedBallot = profileVoteInfo[RECIEVED_BALLOT] === YES
  const notReceivedBallot = profileVoteInfo[RECIEVED_BALLOT] === NO
  const isEarlyVoting = profileVoteInfo[IS_EARLY_VOTING] === YES
  const isNotEarlyVoting = profileVoteInfo[IS_EARLY_VOTING] === NO
  const isMonitoringStatus = user[IS_MONITORING_STATUS] === true

  const stateIsTrackingBallots = info && info.external_tool_absentee_ballot_tracker?.value
  const restrictedState = info.vbm_no_excuse.value === 'False'
  const fullStateName = stateNames[user.state] || user.state
  const exclusiveVBM = info && info.vbm_universal.value
  const allowsEarlyVoting = info && info['2020_early_voting_starts'].value

  const howAreYouRegisteredRef = useRef()
  const signedUpForBallotTrackingRef = useRef()
  const receivedBallotRef = useRef()
  const whenDoYouVoteRef = useRef()
  const didYouVoteRef = useRef()

  useEffect(() => {
    if (scrollToSection) {
      if (scrollToSection.match(/select/gi)) {
        whenDoYouVoteRef?.current?.scrollIntoView()
        return
      }
      if (scrollToSection.match(/confirm/gi)) {
        howAreYouRegisteredRef?.current?.scrollIntoView()
        return
      }
      if (scrollToSection.match(/tracked/gi)) {
        signedUpForBallotTrackingRef?.current?.scrollIntoView()
        return
      }
      if (scrollToSection.match(/received/gi)) {
        receivedBallotRef?.current?.scrollIntoView()
        return
      }
      if (scrollToSection.match(/vote/gi)) {
        didYouVoteRef?.current?.scrollIntoView()
        return
      }
    }
    scrollToTop()
  }, [scrollToSection])

  const canVote = () => {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    const currentDate = [year, month, day].join('-')

    return allowsEarlyVoting ? currentDate >= info.l2020_early_voting_starts_date.value : currentDate === info['2020_general_election_date'].value
  }

  const votedClick = () => {
    handleAnswer(DID_VOTE_2020, YES)
    setDisplayOverlay(true)
  }

  return (
    <>
      {info && (
        <>
          {!isMonitoringStatus && usesInternalRegCheck && (
            <>
              <h3>
                Do you want to monitor your voter status? We can automatically check it and alert you if your status changes without your permission.
              </h3>
              <button className="btn btn-primary thin-button" onClick={() => handleButtonClick(IS_MONITORING_STATUS, AUTO_MONITOR_STATUS, true)}>
                Monitor Registration Status
              </button>
            </>
          )}

          <h3>How are you registered to vote?</h3>
          <form ref={howAreYouRegisteredRef}>
            <Form.Check
              custom
              type="checkbox"
              id="mailin-absentee"
              label={'By Mail or Absentee'}
              checked={byMailOrAbsentee}
              disabled={requestInProcess}
              onChange={() => handleAnswer(VOTE_METHOD, byMailOrAbsentee ? null : BY_MAIL_OR_ABSENTEE)}
            />
            <Form.Check
              custom
              type="checkbox"
              id="in-person"
              label={'In Person'}
              disabled={exclusiveVBM || requestInProcess}
              checked={inPerson}
              onChange={() => handleAnswer(VOTE_METHOD, inPerson ? null : IN_PERSON)}
            />
            <Form.Check
              custom
              type="checkbox"
              id="not-sure"
              label={'Not sure'}
              disabled={exclusiveVBM || requestInProcess}
              checked={notSure}
              onChange={() => handleAnswer(VOTE_METHOD, notSure ? null : NOT_SURE)}
            />
          </form>

          {notSure && (
            <>
              <h3>Not sure?</h3>
              <p>
                {fullStateName} lets you check your voting method{' '}
                {info && <ExternalLink link={'here'} url={info.external_tool_verify_status.value} />}
              </p>
            </>
          )}

          {byMailOrAbsentee && !exclusiveVBM && restrictedState && (
            <p>
              Check vote-by-mail rules for {fullStateName} {info && <ExternalLink link={'here'} url={info.official_info_vbm.value} />}
            </p>
          )}

          {!notSure && !exclusiveVBM && (
            <p>
              Want to change how you're registered to vote in {fullStateName}?{' '}
              {info && <ExternalLink link="Change your method here" url={info.sos_election_website.value} />}
            </p>
          )}

          {exclusiveVBM && <p>Your state votes by mail exclusively.</p>}

          {byMailOrAbsentee && (
            <>
              {' '}
              {!exclusiveVBM && stateIsTrackingBallots && (
                <>
                  <h3>Have you signed up for ballot tracking?</h3>
                  <form ref={signedUpForBallotTrackingRef}>
                    <Form.Check
                      custom
                      type="checkbox"
                      id="is-tracking"
                      label={'Yes'}
                      checked={isTrackingBallot}
                      disabled={requestInProcess}
                      onChange={() => handleAnswer(BALLOT_TRACKING, isTrackingBallot ? null : YES)}
                    />
                    <Form.Check
                      custom
                      type="checkbox"
                      id="not-tracking"
                      label={'No'}
                      checked={isNotTrackingBallot}
                      disabled={requestInProcess}
                      onChange={() => handleAnswer(BALLOT_TRACKING, isNotTrackingBallot ? null : NO)}
                    />
                  </form>
                </>
              )}
              {isNotTrackingBallot && !exclusiveVBM && stateIsTrackingBallots && (
                <p>
                  {fullStateName} lets you track your ballot so you know when it gets counted.{' '}
                  {info && <ExternalLink link={'Sign up here'} url={info.external_tool_absentee_ballot_tracker.value} />}
                </p>
              )}
              <h3>Have you received your ballot?</h3>
              <form ref={receivedBallotRef}>
                <Form.Check
                  custom
                  type="checkbox"
                  id="received-ballot"
                  label={'Yes'}
                  checked={receivedBallot}
                  disabled={requestInProcess}
                  onChange={() => handleAnswer(RECIEVED_BALLOT, receivedBallot ? null : YES)}
                />
                <Form.Check
                  custom
                  type="checkbox"
                  id="not-received-ballot"
                  label={'No'}
                  checked={notReceivedBallot}
                  disabled={requestInProcess}
                  onChange={() => handleAnswer(RECIEVED_BALLOT, notReceivedBallot ? null : NO)}
                />
              </form>
              {receivedBallot && (
                <>
                  <h3 ref={didYouVoteRef}>Have you voted?</h3>
                  <button onClick={() => votedClick()} className="btn btn-primary btn-block final-button">
                    Yes, I voted
                  </button>
                </>
              )}
            </>
          )}

          {inPerson && (
            <>
              {allowsEarlyVoting ? (
                <>
                  <h3>When do you plan to vote?</h3>
                  <form ref={whenDoYouVoteRef}>
                    <Form.Check
                      custom
                      type="checkbox"
                      id="early-voting"
                      label={`Early (${getShortDate(info.l2020_early_voting_starts_date.value)} - ${getShortDate(
                        info.l2020_early_voting_ends_date.value
                      )})`}
                      checked={isEarlyVoting}
                      disabled={requestInProcess}
                      onChange={() => handleAnswer(IS_EARLY_VOTING, isEarlyVoting ? null : YES)}
                    />
                    <Form.Check
                      custom
                      type="checkbox"
                      id="not-early-voting"
                      label={'On Nov 3'}
                      checked={isNotEarlyVoting}
                      disabled={requestInProcess}
                      onChange={() => handleAnswer(IS_EARLY_VOTING, isNotEarlyVoting ? null : NO)}
                    />
                  </form>
                </>
              ) : (
                <h3>
                  Your state does not have early voting.
                  <br />
                  <span>Vote in person on November 3rd.</span>
                </h3>
              )}

              {canVote() && (
                <>
                  <h3>Have you voted?</h3>
                  <button ref={didYouVoteRef} onClick={() => votedClick()} className="btn btn-primary btn-block final-button">
                    Yes, I voted
                  </button>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

RegisteredForm.defaultProps = {
  info: null,
  pending: false,
  usesInternalRegCheck: false,
  scrollToSection: '',
  requestInProcess: false,
}

RegisteredForm.propTypes = {
  user: PropTypes.object.isRequired,
  handleAnswer: PropTypes.func.isRequired,
  setDisplayOverlay: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  info: PropTypes.object,
  pending: PropTypes.bool,
  usesInternalRegCheck: PropTypes.bool.isRequired,
  profileVoteInfo: PropTypes.object,
  scrollToSection: PropTypes.string,
  requestInProcess: PropTypes.bool,
}

export default RegisteredForm
