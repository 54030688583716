import { Fragment } from 'react'
import { Image as Img } from 'react-bootstrap'

const User = '/static/landslide/icons/User.png'
const CrewMember = '/static/landslide/icons/CrewMemberGrey.png'
const GreenCrewMember = '/static/landslide/icons/GreenCrewMember.png'

export function renderInviteButton(isPending) {
  return (
    <div className="invite-friend-container">
      <div className="invite-pic-container">
        <Img src={User} className="invite-icon" />
      </div>
      <div className="invite-title-container">
        {isPending ? (
          <Fragment>
            <h3 className="mt-2">Pending Invitation...</h3>
          </Fragment>
        ) : (
          <Fragment>
            <h3>Invite Member</h3>
            <p className="description invite-member-subtitle">Tap to send the invite</p>
          </Fragment>
        )}
      </div>
    </div>
  )
}

// only renders three until you hit 4 crew members,
// which then renders an additional two
export function renderCrew(completedMembers) {
  // 4 + you
  if (completedMembers > 4) {
    return (
      <Fragment>
        <Img src={GreenCrewMember} className="crew-status-icon ml-2" />
        <Img src={GreenCrewMember} className="crew-status-icon ml-1" />
        <Img src={GreenCrewMember} className="crew-status-icon ml-1" />
        <Img src={completedMembers >= 5 ? GreenCrewMember : CrewMember} className="crew-status-icon ml-1" />
        <Img src={completedMembers >= 6 ? GreenCrewMember : CrewMember} className="crew-status-icon ml-1" />
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        {/* you + 1 */}
        <Img src={completedMembers >= 2 ? GreenCrewMember : CrewMember} className="crew-status-icon ml-2" />
        {/* you + 2 */}
        <Img src={completedMembers >= 3 ? GreenCrewMember : CrewMember} className="crew-status-icon ml-1" />
        {/* you + 3 */}
        <Img src={completedMembers >= 4 ? GreenCrewMember : CrewMember} className="crew-status-icon ml-1" />
      </Fragment>
    )
  }
}
