import React from 'react'
import PropTypes from 'prop-types'
import stateNames from '../../constants/stateNames'
import sortBy from 'lodash/sortBy'

function InfluenceByState({ states }) {
  let scores = []
  for (const [key, value] of Object.entries(states)) {
    const state = stateNames[key] || 'n/a'
    scores.push({ state, ...value })
  }
  scores = sortBy(scores, (o) => o.invite).reverse()
  return (
    <div id="influence-by-state">
      <p className="lg">So far you've influenced elections in:</p>
      <ul>
        {scores.map((s, i) => {
          return s.invite && s.invite !== 0 ? (
            <li key={i}>
              {s?.state} ({s?.invite} {s?.invite === 1 ? 'voter' : 'voters'})
            </li>
          ) : null
        })}
      </ul>
    </div>
  )
}

InfluenceByState.propTypes = {
  states: PropTypes.object.isRequired,
}

export default InfluenceByState
